import {
  GET_RANDOM_QUESTION,
  SET_USER_RESPONSE,
  POST_RUN_CODE,
  GET_TEST_CASE_RESULTS,
  EXECUTE_TEST_CASE,
  GET_ALL_QUESTIONS,
  SUBMIT_TEST,
  SET_ANSWERS,
  SAVE_QUESTION_ANSWER,
} from "./actionsTypes";
import { getEndpointFromType } from "./api";

export const getQuestion = () => ({
  type: GET_RANDOM_QUESTION,
});


export const setAnswers = (data) => ({
  type: SET_ANSWERS,
  data: data,
});

export const setCandidatResponse = (data) => {
  return {
    type: SET_USER_RESPONSE,
    payload: { data },
  };
};

export const postRunCode = (data) => {
  return {
    type: POST_RUN_CODE,
    payload: {
      data,
      runTest: true,
    },
  };
};

export const getRunCodeResults = (data) => {
  return {
    type: GET_TEST_CASE_RESULTS,
    payload: {
      data,
    },
  };
};

export const executeTestsCases = (data) => ({
  type: EXECUTE_TEST_CASE,
  request: {
    url: "/codeexecution",
    method: "post",
    data: data,
  },
});

export const getAllQuestions = (invitationId, counter) => ({
  type: GET_ALL_QUESTIONS,
  request: {
    url: "/ordered-question/" + invitationId,
  },
  meta: {
    onSuccess: (response, requestAction, { dispatch }) => {
      const allAnswers = response.data?.map(({ candidateAnswer }) =>
        candidateAnswer !== null ? candidateAnswer : {}
      );

      dispatch(setCandidatResponse(allAnswers[counter-1]));
      dispatch(setAnswers(allAnswers));
      return response;
    },
    onError: (error) => {
      [404, 403].includes(error.response.status) && (window.location = "#/Validation");
    }
  },
});

export const submitTest = (invitationId) => ({
  type: SUBMIT_TEST,
  request: {
    url: `/confirmation/submitTest/${invitationId}`,
  },
  meta: {
    onSuccess: (response) => {
      window.location = "#/Validation";
      return response;
    }
  }
});

export const saveQuestion = (
  data,
  questionType,
  counter,
  { totalQuestions, isOrderedCampaign, invitationId },
  isNext,
  index,
  durationExpired,
  toggleModalFinishConfirm,
  success
) => {
  return {
    type: SAVE_QUESTION_ANSWER,
    request: {
      url: getEndpointFromType(questionType),
      method: data.id ? "put" : "post",
      data: data,
    },
    meta: {
      onSuccess: (response, e, { dispatch, getState }) => {
        if (!isOrderedCampaign) {
          if(counter < totalQuestions) {
            dispatch(getQuestion());
            dispatch(setCandidatResponse({}));
          }
        } else {
          const questions = getState()?.questions;
          const newAnswers = [...questions?.answers];
          newAnswers[counter - 1] = {
            id: response.data.id,
            ...questions.candidatResponse,
            isChanged: false
          };
          dispatch(setAnswers(newAnswers));
          let newCounter = isNext ? counter : counter - 2;
          if(index !== undefined) newCounter = index-1;

          if(counter !== totalQuestions || !isNext) dispatch(setCandidatResponse({...newAnswers[newCounter], isChanged: false}));
        }

        if (isNext) {
          if (counter === totalQuestions) {
            if(isOrderedCampaign && !durationExpired) {
              toggleModalFinishConfirm();
            } else dispatch(submitTest(invitationId));
          }
        }
        if (success) success(isNext, index, undefined, durationExpired);
        return response;
      },

      onError: (error) => {
        error.response.status === 403 && (window.location = "#/Success");
      }
    },
  };
};
