import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer, { reduxRequestsMiddleware } from "../../redux";
import rootSaga from "../../redux/sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// mount it on the Store
export default createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, ...reduxRequestsMiddleware))
);

// then run the saga
sagaMiddleware.run(rootSaga);
