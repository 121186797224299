import React, { Component } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/logo.png";
import { t } from "../../i18n";
import { parse } from "query-string";
import { CONTACT_US } from "../../shared/config/constants";

export class BadToken extends Component {
  render() {
    return (
      <div className="ah-container">
        <img
          src={logo}
          alt="Novelis logo"
          className="ah-nvlogo img-fluid mx-auto"
        />
				<h3 className="ah-badtokenh3">{t("Oops_othing_to_see_here!")}</h3>

        <FontAwesomeIcon
          icon={faExclamation}
          size="10x"
          className="ah-exclamation"
        />
				<p className="ah-paragraph">
					{t("You_can_not_unfortunately_access_campaign")}
					<br />
					<span className="ah-contact-us">
						{t("you_an_send_us_your_resume_to")}
						{this.props.contactUS ? this.props.contactUS : CONTACT_US}
					</span>
				</p>
			</div>
		);
	}
}
const mapStateToPros = ({ authentication, campaignLink }) => {
	return {
		contactUS: parse(window.location.search).reg
			? campaignLink.campaign.campaignLinkDTO
			: authentication.invitation.emailRecuiter,
	};
};

export default connect(mapStateToPros)(BadToken);
