import React, { Component } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import cs from "classnames";

class Checkbox extends Component {
  state = {
    isChecked: false
  };

  toggleCheckboxChange = () => {
    const { handleCheckboxChange, value } = this.props;
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked
    }));

    handleCheckboxChange(value);
  };

  componentDidMount () {
    this.setState({
      isChecked: this.props.isChecked || false
    })
  }
  
  componentWillReceiveProps(nextProps) {
    if(this.state.isChecked !== nextProps.isChecked){
      this.setState({isChecked: nextProps.isChecked})
    }
  }

  render() {
    const {input, label, value, meta, className } = this.props;
    const { isChecked } = this.state;

    return (
      <FormGroup className={cs("mb-0", className)}>
          <Input
            id={label}
            type="checkbox"
            value={value || (input && input.value)}
            checked={isChecked || (input && input.value)}
            onChange={value ? this.toggleCheckboxChange : e => {
              input.onChange(e.target.checked);
            }}
            {...input}
            className={cs("react-select", { "is-invalid": meta && meta.touched && meta.error })}
          />
          <Label for={label} className="text-label">{label}</Label>
          {meta &&
          meta.touched &&
					((meta.error && <span className="text-danger">{meta.error}</span>) ||
						(meta.warning && <span className="text-danger">{meta.warning}</span>))}
        </FormGroup>
    );
  }
}

export default Checkbox;
