import React, { Component } from "react";
import { getQuery } from '@redux-requests/core';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col } from "reactstrap";
import { isEmpty, isNull } from "lodash";

import CustomCountdown from "../../components/CustomCountDown";

import { server_uri_api } from "shared/config/constants";
import { getFormattedDuration } from "shared/utils/functions";
import { GET_CURRENT_ROOT_ENTITY } from "redux/rootEntity/actionsTypes";
import { DOWNLOAD_LOGO_ENTITY } from "redux/rootEntity/endpoints";

import { t } from "../../i18n";
import { connect } from "react-redux";

class CompagneInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      campaignInfo = {},
      onComplete,
      restTimeToEnd,
      currentRootEntity
    } = this.props;
    return (
      <Row>
        <Col md={12} className="text-center">
        {isEmpty(currentRootEntity) ||
				 isNull(currentRootEntity.logoEntity) ?
				   "":
					 <img
							src={`${server_uri_api}${DOWNLOAD_LOGO_ENTITY}/${currentRootEntity.name}/${currentRootEntity.logoEntity}`}
							alt="Compagne logo"
							className="w-50"
				   />
				 }
          <div className="h3 mt-4">{campaignInfo?.title}</div>
          <span className="mr-4 mt-4 d-inline-block">
            <FontAwesomeIcon icon={faClock} /> {getFormattedDuration(new Date(campaignInfo?.duration * 1000).toISOString().substring(11,19))}
          </span>
          <FontAwesomeIcon icon={faQuestionCircle} />{" "}
          {campaignInfo.totalQuestions}
          {" " + t("questions")}
          <div className="h6 mt-4">{t("The_invitation_expires_in")}</div>
          <CustomCountdown
            date={restTimeToEnd}
            onComplete={onComplete}
            fullTime={false}
            dangerTime={60}
          />
        </Col>
      </Row>
    );
  }
}
const mapStateToPros = ({ authentication, ...state }) => {
  return {
    campaignInfo: authentication.campaign,
    restTimeToEnd: authentication.restTimeToEnd,
    currentRootEntity: getQuery(state, {
			type: GET_CURRENT_ROOT_ENTITY,
		}).data,	
  };
};

export default connect(mapStateToPros)(CompagneInfo);
