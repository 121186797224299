import React from "react";
import { Row, Col } from "reactstrap";
import { Label } from "reactstrap";
import cs from "classnames";

export const FormFields = ({
	children,
	label,
	id,
	className,
	labelClassName,
	meta: { touched, error, warning },
	...rest
}) => (
	<div className={className}>
		<Row className="mb-4">
			<Col xs={12} sm={12}>
				{label && (
					<Label htmlFor={id || rest.input.name} className={cs(labelClassName, "text-label")}>
						{label}
					</Label>
				)}
			</Col>
			<Col xs={12} sm={12}>
				{children}

				{touched &&
					((error && <span className="text-danger">{error}</span>) ||
						(warning && <span className="text-danger">{warning}</span>))}
			</Col>
		</Row>
	</div>
);
