import { POST_COMMENT_DATA, POST_VIDEO_COMMENT_DATA } from "./actionsTypes";

export const postComment = (data, success) => {
  return { type: POST_COMMENT_DATA, payload: { data, success }, loading: true };
};

export const postVideoComment = (data, success, config) => {
  return {
    type: POST_VIDEO_COMMENT_DATA,
    payload: { data, success, config },
    loading: true,
  };
};
