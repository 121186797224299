import React from "react";
import { Button} from "reactstrap"; 

export const ButtonG = ({ className, ...rest }) => {
  return (
    <Button className={`ah-button ${className}`} {...rest}>
      {rest.children}
    </Button>
  );
};
