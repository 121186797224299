import { RECEIVE_INSTRUCTION_DATA } from "./actionsTypes";

const INIT_STATE = {
  data: []
};

// eslint-disable-next-line
export default (state = INIT_STATE, { type, instructions }) => {
  switch (type) {
    case RECEIVE_INSTRUCTION_DATA:
      return { ...state, data: instructions };
    default:
      return state;
  }
};
