import { call, takeEvery, put } from "redux-saga/effects";
import { getCampaignInfoByLink } from "./api";

import {
	GET_CAMPAIGN_LINK_DATA,
	GET_CAMPAIGN_LINK_ERROR,
} from "./actionsTypes";
import {
  getCurrentRootEntity
} from "../rootEntity/action";

import { getCampaignLinkDone } from "./action";

export function* getCampaignLinkSaga({ link }) {
	try {
		const data = yield call(getCampaignInfoByLink, link);

		yield put(getCampaignLinkDone(data));
		yield put(getCurrentRootEntity());
		if (data.idToken !== null && !localStorage.getItem("authenticationToken")) {
			localStorage.setItem("authenticationToken", data.idToken);
		}
	} catch (error) {
		yield put({ type: GET_CAMPAIGN_LINK_ERROR, error });
	}
}

export default function* actionWatcherCandidate() {
	yield takeEvery(GET_CAMPAIGN_LINK_DATA, getCampaignLinkSaga);
}
