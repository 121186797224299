import React, { Component } from "react";
import PropTypes from "prop-types";
import { t } from "../../i18n";
import cs from "classnames";

/**
 * Note :
 * If you're using react v 15.4 or less
 * You can directly import PropTypes from react instead.
 * Refer to this : https://reactjs.org/warnings/dont-call-proptypes.html
 */

let count;
class CustomCountdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };
  }

  componentDidMount() {
    // update every second
    count = Math.floor(this.props.date / 1000);

    this.interval = setInterval(() => {
      const date = this.calculateCountdown();

      date ? this.setState(date) : this.renderer();
    }, 1000);
  }

  renderer = () => {
    return this.props.onComplete ? this.props.onComplete : this.onStop();
  };

  componentWillUnmount() {
    this.onStop();
  }

  onStop = () => {
    clearInterval(this.interval);
  };

  calculateCountdown() {
    //diff en Second
    let diff = --count;

    // clear countdown when date is reached
    if (diff < 0) {
      this.props.onComplete();
      return false;
    }

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;

    return this.props.fullTime ? (
      <div className="d-md-flex">
        <span className="d-flex justify-content-center flex-column align-items-center ah-size2">
          <span className="h2">{this.addLeadingZeros(countDown.days)}</span>
          <span className="ah-s2-txt1">
            {countDown.days === 1 ? t("Day") : t("Days")}
          </span>
        </span>

        <div className="d-flex justify-content-center flex-column align-items-center ah-sep">
          :
        </div>

        <span className="d-flex justify-content-center flex-column align-items-center ah-size2">
          <span className="h2">{this.addLeadingZeros(countDown.hours)}</span>
          <span className="ah-s2-txt1">{t("Hours")}</span>
        </span>

        <div className="d-flex justify-content-center flex-column align-items-center ah-sep">
          :
        </div>

        <span className="d-flex justify-content-center flex-column align-items-center ah-size2">
          <span className="h2">{this.addLeadingZeros(countDown.min)}</span>
          <span className="ah-s2-txt1">{t("Minutes")}</span>
        </span>

        <div className="d-flex justify-content-center flex-column align-items-center ah-sep">
          :
        </div>

        <span className="d-flex justify-content-center flex-column align-items-center ah-size2">
          <span className="h2">{this.addLeadingZeros(countDown.sec)}</span>
          <span className="ah-s2-txt1">{t("Seconds")}</span>
        </span>
      </div>
    ) : (
      <p
        className={cs("ah-p-standard", {
          "danger-time": this.props.dangerTime && count <= this.props.dangerTime
        })}
      >
        {this.addLeadingZeros(countDown.days) &&
        this.addLeadingZeros(countDown.days) !== "00"
          ? this.addLeadingZeros(countDown.days) + ":"
          : ""}
        {this.addLeadingZeros(countDown.hours) &&
        this.addLeadingZeros(countDown.hours) !== "00"
          ? this.addLeadingZeros(countDown.hours) + ":"
          : ""}
        {this.addLeadingZeros(countDown.min) +
          ":" +
          this.addLeadingZeros(countDown.sec)}
      </p>
    );
  }
}

CustomCountdown.propTypes = {
  date: PropTypes.number.isRequired
};

export default CustomCountdown;
