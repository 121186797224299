import {
  GET_CAMPAIGN_INFO_SUCCESS,
  GET_CAMPAIGN_INFO_DATA
} from "./actionsTypes";

const initialState = {
  data: [],
  invitations: {},
  loading: false
};

// eslint-disable-next-line
export default (state = initialState, payload) => {
  switch (payload.type) {
    case GET_CAMPAIGN_INFO_DATA:
      return { ...state, loading: true };
    case GET_CAMPAIGN_INFO_SUCCESS:
      return { ...state, invitations: payload.data, loading: false };
    default:
      return state;
  }
};
