import React, { Component } from "react";
import { Input, Label, FormGroup } from "reactstrap";

class RadioButton extends Component {
  render() {
    const { label, id, ...restProps } = this.props;

    return (
      <FormGroup check className="ah-radiobutton">
        <Input id={label} type="radio" {...restProps} />
        <Label for={label}>{label}</Label>
      </FormGroup>
    );
  }
}

export default RadioButton;
