import React, { Component } from "react";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import {
  setCandidatResponse,
  postRunCode,
  executeTestsCases
} from "../../../redux/questions/action";
import {  EXECUTE_TEST_CASE } from "../../../redux/questions/actionsTypes";
import { getMutation } from '@redux-requests/core';
import { Loader } from "../../Loader";
import { t } from "../../../i18n";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  ListGroup,
  ListGroupItem,
  PopoverHeader,
  PopoverBody,
  Popover,
  Badge,
} from "reactstrap";

import AceEditor from "react-ace";
import "brace/mode/java";
import "brace/mode/php";
import "brace/mode/python";
import "brace/theme/monokai";
import "brace/theme/kuroir";
import "brace/ext/language_tools";
import { Select } from "../../form/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faTimesCircle,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import cs from "classnames";
import QuestionContent from "components/QuestionContent";


const themeOptions = [
  { value: "monokai", label: "Monokai" },
  { value: "kuroir", label: "Kuroir" },
];

const modifiers = {
  flip: {
    enabled: false,
  },
};

class TestCodingCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langages: undefined,
      modal: false,
      themes: themeOptions[0],
      testCodingResults: undefined,
      tooltipOpen: false,
    };
  }

  handleChangeTheme(e) {
    this.setState({
      themes: e,
    });
  }

  componentDidMount() {
    const { defaultCodes } = isEmpty(this.props.question)
      ? this.props.mock
      : this.props.question;

    let languageUsed;
    if(this.props.language){
      languageUsed = defaultCodes.find(({refLanguage}) => refLanguage.language.toLowerCase() ===  this.props.language.toLowerCase());
    }
    else languageUsed = defaultCodes[0]

    const firstItem = languageUsed?.refLanguage;
    const initLanguage = this.getLanguageObject(firstItem);
    const language = get(this.state, "langages", initLanguage);
    this.setState({ langages: language });
  }

  componentDidUpdate(prevProps) {
    const { defaultCodes } = isEmpty(this.props.question) ? this.props.mock  : this.props.question;
    const initLanguage = this.getLanguageObject(defaultCodes[0].refLanguage);
    const isLangNotIncluded = defaultCodes.find(({refLanguage}) => refLanguage.language?.toLowerCase() === this.state.langages.value.toLowerCase()) === undefined

    if(this.props.language && this.props.language !== prevProps.language) {
      const languageUsed = defaultCodes.find(({refLanguage}) => refLanguage.language?.toLowerCase() ===  this.props.language?.toLowerCase());
      const firstItem = languageUsed?.refLanguage;
      const langages = this.getLanguageObject(firstItem);
      this.setState({ langages });
    } else if (this.props.language === undefined && isLangNotIncluded) {
      this.setState({ langages: initLanguage });
    }
  }

  getLanguageObject = (refLanguage) => {
    return {
      id: get(refLanguage, "id"),
      value: get(refLanguage, "language"),
      label: get(refLanguage, "language"),
    }
  } 

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.testCodingResults?.codeId === this.props.question?.id) {
      this.setState({ testCodingResults: nextProps.testCodingResults });
    }
  };

  handleChangeLangage = (e) => {
    if (get(this.state, "langages.value") !== e.value) {
      if (get(this.props, "candidatResponse.submittedCode")) {
        this.toggleModal();
        this.setState({ tmpLangages: e });
      } else {
        this.setState({ langages: e });
      }
    }
  };

  confirmChangeLangage = () => {
    this.toggleModal();
    this.onChange(undefined);
    this.setState({ langages: this.state.tmpLangages });
  };
  toggleModal = () => this.setState({ modal: !this.state.modal });

  toggleRunCode = (reexec) => {
   if (get(this.props, "candidatResponse.submittedCode")) {
      this.setState({ testCodingResults: undefined }, () => {
        const CodeExecutionDto = !isEmpty(this.props.question) ? {
          refLanguage: this.state.langages,
          code: this.props.candidatResponse.submittedCode,
          codeId: this.props.question.id
        } : {
          language: this.state.langages.label,
          code: this.props.candidatResponse.submittedCode,
          testCaseSeparator: ":",
          testCases: this.props.mock.testCases,
        }
  
        !isEmpty(this.props.question) ? this.props.postRunCode(CodeExecutionDto) : this.props.executeTestsCases(CodeExecutionDto).then(({data} )=> this.setState({testCodingResults: data}));
  
        if(reexec === false)
         this.props.toggleCodeModal();
      });
    }
  };

  closeCodeModal = () => {
    this.props.toggleCodeModal();
  };

  onChange = (newValue) => {
    const { defaultCodes } = isEmpty(this.props.question)
      ? this.props.mock
      : this.props.question;
    var language = this.state.langages
      ? this.state.langages.value
      : get(defaultCodes, "[0].refLanguage.language");
    this.props.setCandidatResponse({
      submittedCode: newValue,
      language: language ? language.toUpperCase() : "",
      isChanged: true
    });
  };

  toggleToolTip = () => {
    let tooltipOpen = !this.state.tooltipOpen;
    this.setState({ tooltipOpen });
  };

  render() {
    const { content, defaultCodes } = isEmpty(this.props.question)
      ? this.props.mock
      : this.props.question;
    const defaultValues = defaultCodes.filter(
      (i) => i.refLanguage.language === get(this.state.langages, "value")
    );

    const languagesOptions = defaultCodes.map((item) => ({
      id: item.refLanguage.id,
      value: item.refLanguage.language,
      label: item.refLanguage.language,
    }));

    const defaultValue =
      defaultValues.length > 0 ? defaultValues[0].boilerplate : "";
    let { tooltipOpen } = this.state;
    
    const question = isEmpty(this.props.question) ? this.props.mock : this.props.question;
    const publicTestCaseExist = question.testCases.some(testCase => testCase.public);

    const testCases = this.state.testCodingResults?.testCases || this.state.testCodingResults; // question tc || mock tc
    return (
      <Row  className="h-100 m-0 ah-editor-align ah-content">
          <Col xs={6} sm={5} className="h-100">
          { publicTestCaseExist && <div>
            <Button
              className={`ah-button_run-test`}
              onClick={() => this.toggleRunCode(false)}
              id="PopoverClick"
            >
              {" "}
              <FontAwesomeIcon
                icon={!this.props.modalRunCode ? faPlayCircle : faTimesCircle}
                size="3x"
                className="ah-text-primary"
              />
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="PopoverClick"
                trigger={this.props.candidatResponse?.submittedCode ? "click focus" : "hover focus" }
                toggle={this.toggleToolTip}
              >
                {this.props.candidatResponse?.submittedCode ? t('TestYourCode') : t('EnterCodeToTest') }
              </Tooltip>
            </Button>
          </div> }
          <div className="overflow-auto h-100">
            <QuestionContent isCode={true} content={content} />
          </div>
        </Col>
        <Col xs={6} sm={7} className="ah-editor-wrapper">
          <div className="ah-question-content text-right">
            <Select
              className="ah-selectStyle d-inline-block"
              label="Langage"
              onChange={this.handleChangeLangage.bind(this)}
              value={this.state.langages || languagesOptions[0]}
              options={languagesOptions}
            />
            <Select
              className="ah-selectStyle d-inline-block ml-2"
              label="Theme"
              onChange={this.handleChangeTheme.bind(this)}
              value={this.state.themes}
              options={themeOptions}
            />
          </div>
          <div className="ah-Editor ">
            <AceEditor
              onChange={this.onChange}
              placeholder="Placeholder Text"
              mode={get(this.state.langages, "value")}
              value={
                get(this.props, "candidatResponse.submittedCode") || defaultValue || ""
              }
              theme={this.state.themes.value}
              name="Novelis"
              onLoad={this.onLoad}
              fontSize={"14"}
              width={"100%"}
              height={"100%"}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              editorProps={{
                $blockScrolling: Infinity,
              }}
            />
          </div>
        </Col>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalBody>{t("change_select_language_warning")}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              {t("Cancel")}
            </Button>
            <Button color="danger" onClick={this.confirmChangeLangage}>
              {t("Confirm")}
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {publicTestCaseExist && <Popover
          modifiers={modifiers}
          placement="left"
          isOpen={this.props.modalRunCode}
          target="PopoverClick"
          //  toggle={this.toggleRunCode}
          className={"ah-popover"}
        >
          <PopoverHeader className={"ah-modal-header text-center clearfix"}>
            <span>{t('TestYourCode')}</span>
            <Button
              id="PopoverClick"
              onClick={this.closeCodeModal}
              className="btn btn-danger float-right"
            >
              X
            </Button>
          </PopoverHeader>
          <PopoverBody className="ah-popover-body p-0">
              {this.state.testCodingResults ? (
              testCases?.map((result, index) => {
                return (
									result.public && (
										<ListGroup className="ah-popover_list" key={index}>
											<ListGroupItem
												key={index}
												tag="a"
												action
												className="ah-testResults-tc-item"
											>
												<span className="ah-modal-body-labels pr-3">
													{" "}
													{result.title}
												</span>
												{result.runDto.signal === null ? (
													result.success ? (
														<Badge className="ah-testResults-tc-badge-success px-2">
															{t("success")}
														</Badge>
													) : (
														<Badge className="badge badge-danger px-2">
															{t("failed")}
														</Badge>
													)
												) : (
													<span
														className={cs("ah-modal-body-labels", {
															resultSuccess: result.success,
															resultFailed: !result.success,
														})}
													>
														{result.runDto.signal === "SIGKILL" &&
															"run timeout"}
													</span>
												)}
												<br />
												<Badge className="ah-testResults-tc-badge-dark px-2">
													{t("Argument")}
												</Badge>
												<span className="ah-testResults-tc-header px-2">
													{" "}
													{result.input}
												</span>
												<br />
												<Badge className="ah-testResults-tc-badge-info px-2">
													{t("expected.output")}
												</Badge>
												<span className="ah-testResults-tc-header px-2">
													{" "}
													{result.expectedOutput}
												</span>
												<br />
												<Badge className="ah-testResults-tc-badge-dark px-2">
													{t("candidate.output")}
												</Badge>
												<span className="ah-testResults-tc-header px-2">
													{" "}
													{result.runDto.stdout}{" "}
												</span>
												<br />
												<Badge className="ah-testResults-tc-badge-dark  px-2">
													{t("messages.output")}
												</Badge>
												<span className="ah-testResults-tc-header px-2">
                          {result.runDto.stdErr}
												</span>
											</ListGroupItem>
										</ListGroup>
									)
								);
                })
                ) : (
                  <Loader />
                )
            
            }
          </PopoverBody>
          <div className="button-section">
            <button type="button" className={"btn btn-info mx-auto"} onClick={() => this.toggleRunCode(true)}>
              <FontAwesomeIcon icon={faRedo} />
              <span className="ml-2 font-weight-bold">{t("retest")}</span>
            </button>
          </div>
        </Popover>}
      </Row>
    );
  }
}

const mapStateToPros = ({ questions, ...state }) => {
  return {
    candidatResponse: questions.candidatResponse,
    testCodingResults:
      questions.resultTestCases && questions.resultTestCases.data,
    codeExecResState: getMutation(state, {
      type: EXECUTE_TEST_CASE,
    }),
  };
};

export const TestCoding = connect(mapStateToPros, {
  setCandidatResponse,
  postRunCode,
  executeTestsCases
})(TestCodingCmp);