import React, {useState, useRef, useEffect} from 'react';
import classnames from "classnames";
import { Navbar, NavbarToggler, Collapse, Nav, NavItem, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getFormattedDuration } from 'shared/utils/functions';

const Sidebar = ({questions, current, onSelectQuestion}) => {
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => {
      setCollapse (prevCollapse => !prevCollapse)
  }
  const sidebarRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickAway);

    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, []);

  const handleClickAway = e => {
    if (!sidebarRef.current.contains(e.target)) {
        setCollapse(false)
    }
  };

  return (
      <div ref={sidebarRef}>
        <Navbar
            color="faded"
            light
        >
            <NavbarToggler
                className="me-2 ah-collapse-icon"
                onClick={() => toggleCollapse() }
            />
            <Collapse horizontal isOpen={collapse} className="ah-collapse collapse-horizontal" >
                <button type="button" class="me-2 collabse-visible navbar-toggler" onClick={() => toggleCollapse() }>
                    <FontAwesomeIcon className="m-2" icon={faTimes} />
                    <i class="fas fa-bars"></i>
                </button>
                <Nav vertical className="mt-2 nav-scroll overflow-auto">
                    {questions?.map((question, index) => {
                        return (
                            <NavItem key={index}>
                                <Button className={classnames({
                                        active: current === index+1
                                    }, "ah-button-text")}
                                    onClick={() => onSelectQuestion(index)}
                                >
                                    <div className='d-flex flex-row justify-content-between align-items-center px-2 py-1'>
                                        <div className='mr-4'>{question.title}</div>
                                        <small className='ah-collapse-duration'>{getFormattedDuration(new Date(question.duration * 1000).toISOString().substring(11,19))}</small>
                                    </div>
                                </Button>
                            </NavItem>
                        )
                    })}
                </Nav>
            </Collapse>
        </Navbar>
    </div>
  )
}
 
export default Sidebar;