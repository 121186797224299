import { call, takeEvery, put, select } from "redux-saga/effects";
import { get } from "lodash";
import { getQuestionApi, postRunCodeApi } from "./api";
import {
  GET_RANDOM_QUESTION,
  GET_RANDOM_QUESTION_SUCCESS,
  POST_RUN_CODE,
  GET_TEST_CASE_RESULTS
} from "./actionsTypes";

export function* postRunCodeSaga({ payload }) {
  try {
    const data = yield call(postRunCodeApi, payload);
    yield put({ type: GET_TEST_CASE_RESULTS, data });
  } catch (error) {
    console.log("error while testing the code with the resolts", error);
  } 
}

export function* getRandomQuestionSaga() {
  const idInvitations = yield select(state =>
    get(state, "authentication.invitation.id")
  );

  try {
    const data = yield call(getQuestionApi, idInvitations);
    yield put({
      type: GET_RANDOM_QUESTION_SUCCESS,
      data
    });
  } catch (error) {
    (error.response.status) === 404 && (window.location = "#/Validation");
  }
}

export default function* actionWatcherQuestion() {
  yield takeEvery(GET_RANDOM_QUESTION, getRandomQuestionSaga);
  yield takeEvery(POST_RUN_CODE, postRunCodeSaga);
}
