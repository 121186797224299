import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { Input } from "../Input";

const renderField = ({
  input,
  label,
  type,
  id,
  meta: { touched, error, warning },
  ...props
}) => (
  <Fragment>
    <Input
      {...input}
      label={label}
      type={type}
      placeholder={label}
      id={id}
      {...props}
    />
    {touched &&
      ((error && (
        <span className="ah-spanError ">
          <FontAwesomeIcon className="mr-1" icon={faExclamationCircle} />
          {error}
        </span>
      )) ||
        (warning && <span>{warning}</span>))}
  </Fragment>
);
export default renderField;
