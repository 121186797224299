import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { get, isArray } from "lodash";
import CompagneInfo from "./CompagneInfo";
import {
	getExperience,
	postCandidate,
	postInvitationCandidate,
	putCandidate
} from "redux/candidat/action";
import Form from "./FormCandidat";
import logo from "assets/img/logo.png";
import ChooseTestDate from "./ChooseTestDate";
import CustomFieldsFom from "./CustomFieldsFom";
import { t } from "i18n";
import { parse } from "query-string";
import spacetime from "spacetime";
import { allTimezones } from "react-timezone-select";

import { fieldsType } from "shared/constants/fieldsType";

const parsed = parse(window.location.search);

const getDefaultTimezone = () => {
	const tz = spacetime().timezone();
	const defaultTimezoneId = tz.name;
	const timezoneOffset = tz.current.offset
	const min = timezoneOffset * 60;
	const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? "00" : Math.abs(min % 60));
	const defaultTimezone = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${allTimezones[defaultTimezoneId] || defaultTimezoneId}`;
	return defaultTimezone;
  }

const CandidateRegistration = (props) => {
	const [cvName, setCvName] = useState("");
	const [messageError, setMessageError] = useState(null);
	const [step, setStep] = useState(1);
	const [candidat, setCandidat] = useState({});
	const [isLoadingAddingCandidate, setisLoadingAddingCandidate] = useState(false);
	const [attachment, setAttachment] = useState(null);
	const [invitation, setInvitation] = useState(null);
	const [timeZone,setTimeZone] = useState(getDefaultTimezone());
	const [startDate,setStartDate] = useState();
    const [isLoadingCreateInvitation, setIsLoadingCreateInvitation] =  useState(false);

	const customFieldsCandidate = {};
	candidat.customField && Object.keys(props.customField).forEach((type) => {
		if(type === fieldsType.SELECT){
			isArray(props.customField[type]) ?
			props.customField[type].forEach((field) =>{
				customFieldsCandidate[field.title] = 
				get(candidat.customField[field.name],
					"label", 
					candidat.customField[field.name]
				);
			}):
			customFieldsCandidate[props.customField[type].title] = 
				get(candidat.customField[props.customField[type].name],
					"label", 
					candidat.customField[props.customField[type].name]
				);

		}else{
			isArray(props.customField[type]) ?
			props.customField[type].forEach((field) =>{
				customFieldsCandidate[field.title] = 
				candidat.customField[field.name];
			})
			:
			customFieldsCandidate[props.customField[type].title] = 
			candidat.customField[props.customField[type].name];
		}	
	});

	useEffect(() => {
		props.getExperience();
	}, []);// eslint-disable-line

	useEffect(()=>{
		if(attachment !== null){
			setisLoadingAddingCandidate(false);
		}
  
	},[attachment])

	useEffect(()=>{
		if(Object.entries(candidat).length !== 0)
		{
			if (props.customField || props.chooseTestTime) {
				setisLoadingAddingCandidate(false);
		    setStep(step + 1);
		  }else {
				props.postCandidate(
				{...candidat, customField:JSON.stringify(customFieldsCandidate) !== "{}" ? 
					JSON.stringify(customFieldsCandidate) : null},
				parsed.reg,
				successSaveCandidate, 
				errorSaveCandidate
			);
		}}
	},[candidat])// eslint-disable-line

	useEffect(()=>{
		if(invitation !== null){  
			props.postCandidate(
				{...candidat, customField:JSON.stringify(customFieldsCandidate) !== "{}" ? 
				JSON.stringify(customFieldsCandidate) : null},
				parsed.reg,
				successSaveCandidate,
				errorSaveCandidate
			);
		}
	},[invitation]) // eslint-disable-line

	const previousForm = () => {
		setStep((step) => step - 1);
	};

	const upload = (file) => {
		setisLoadingAddingCandidate(true);
		setCvName("");
		if (file && file.type === "application/pdf") {
			setMessageError(null);
			let attachment = {
				fileName: file.name,
				fileDownloadUri: "",
				fileType: file.type,
				size: file.size,
				file: null,
			};

			setCvName(file.name);
			getBase64(file, (result) => {
				setAttachment({...attachment, file:result});
			});	
		} else {
			setMessageError(t("candidat.cv-bad-format"));
		}
	};

	function getBase64(file, cb) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result);
		};
		reader.onerror = function (error) {
			setMessageError(error);
		};
	}

	const chooseTime = (data) => {
		setIsLoadingCreateInvitation(true);
		const { campaignDTO, campaignLink } = props; 

		//start date
		const startDate = new Date(data.startDate);
        const startDateUTC = new Date(
         Date.UTC(startDate.getFullYear(), 
        startDate.getMonth(), 
        startDate.getDate(), 
        startDate.getHours(),
        startDate.getMinutes(),
        startDate.getSeconds()));
		data.startDate = startDateUTC.toUTCString();

		//End date
		// convert duration to millisec + 15 min
		const start = startDate;
		const endDate = new Date(
			start.getTime() + 900000
		);
		const endDateUTC = new Date(
		  Date.UTC(endDate.getFullYear(), 
		  endDate.getMonth(), 
			endDate.getDate(), 
			endDate.getHours(),
			endDate.getMinutes(),
			endDate.getSeconds()));
		data.endDate = endDateUTC.toUTCString();

		data.emailRecuiter = get(campaignLink, "emailRecruiter");
		data.logoRecruiter = get(campaignLink,"logoRecruiter");
		data.campaignId = get(campaignDTO, "id");
		data.isDelete = false;
		data.timeZone =get(data.timeZone,"label", timeZone);
		setInvitation(data);
	};

	 //submit
	 const createCandidat = (data) => {
			setisLoadingAddingCandidate(true);
			let newCandidat = {
			...data,
			refExperienceId:get(data.refExperienceId, "value", data.refExperienceId),
			title: get(data.title, "value", data.title),
			tags: props.tags,
			attachment:attachment,
		};		
		setCandidat({...candidat, ...newCandidat});
	};

	const submitCustomFields = (data) => {

		setisLoadingAddingCandidate(true);
		setCandidat({...candidat, customField: data});
	}

	const successSaveCandidate = () => {

		const successCallback = () => {
			setIsLoadingCreateInvitation(false);
			props.history.push("/Success");
		};

		const errorCallback = () => {
			setIsLoadingCreateInvitation(false);
			setMessageError(t("You_have_already_applied"));
		};

		if(props.chooseTestTime){
			props.postInvitationCandidate(
				invitation,
				candidat.email,
				successCallback,
				errorCallback);
		}else{ 
			setisLoadingAddingCandidate(false);
			props.history.push("/Success");
		}

	}

	const errorSaveCandidate = () => {
		setMessageError(t("error.emailexists"));
		isLoadingCreateInvitation(false);
		setisLoadingAddingCandidate(false);
	};

	
	
	const handleSelectChange = (event)=>{
		setTimeZone(event);
	  }  
	const handleSelectChangeStartDate = (event)=>{
		setStartDate(event);
	  }  


	const ChooseDate = () => (
			<ChooseTestDate
				onSubmit={chooseTime}
				messageError={messageError}
				previousForm={previousForm}
				handleSelectChange={handleSelectChange}
				handleSelectChangeStartDate={handleSelectChangeStartDate}
				startDate={startDate}
				timeZone={timeZone}
				//{...initialValues}
				isLoadingCreateInvitation={isLoadingCreateInvitation}
			/>
	)

	function renderComponent() {
		switch (step) {
			case 1:
				return (
					<Form
						upload={upload}
						cvName={cvName}
						onSubmit={createCandidat}
						messageError={messageError}
						candidat={candidat}
						isLoadingAddingCandidate={isLoadingAddingCandidate}
						moreSteps={props.chooseTestTime || props.customField}
					/>
				);

			case 2:
				if(props.customField){
					return (
						<CustomFieldsFom
							customFields={props.customField}
							onSubmit={submitCustomFields}
							isLoadingAddingCandidate={isLoadingAddingCandidate}
							moreSteps={props.chooseTestTime}
							previousForm={previousForm}
							candidat={candidat}
						/>
					);

				}else{
					return ChooseDate();
				}
				
				
			case 3:
				return ChooseDate();

			default:
				return (
					<Form
						upload={upload}
						cvName={cvName}
						onSubmit={createCandidat}
						messageError={messageError}
						candidat={candidat}
					/>
				);
		}
	}

	return (
		<div className="container-fluid h-100  ah-transition">
			<Row className="ah-row">
				<Col xs="12" sm={12} md="6" className="border-right">
					<CompagneInfo
						onComplete={() => {
							props.history.push("/expired");
						}}
					/>
				</Col>
				<Col xs="12" sm={12} md="6">
					{renderComponent()}
				</Col>
				<div xs="6">
					<div className="ah-arrow-Style d-none d-sm-block" />
				</div>
				<div xs="6">
					<img src={logo} className="ah-logo-home-top" alt="logo" />
				</div>
			</Row>
		</div>
	);
};

const mapStateToProps = ({ candidat, campaignLink }) => ({
	experienceOptions: candidat.experiencesOption,
	candidat: candidat && candidat.data,
	tags: campaignLink.tags,
	customField:
		campaignLink.campaign.campaignLinkDTO &&
		JSON.parse(campaignLink.campaign.campaignLinkDTO?.customField),
	chooseTestTime:
		campaignLink.campaign.campaignLinkDTO &&
		campaignLink.campaign.campaignLinkDTO.chooseTestTime,
	campaignLink: campaignLink.campaign.campaignLinkDTO,
	campaignDTO: campaignLink.campaign.campaignDTO,
});

export default connect(mapStateToProps, {
	getExperience,
	postCandidate,
	postInvitationCandidate,
	putCandidate
})(CandidateRegistration);
