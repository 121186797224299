import React from "react";
import Countdown from "react-countdown-now";
import cs from "classnames";

const Completionist = () => <h3> 00:00:00 </h3>;

const defaultRenderer = ({ formatted, completed }, dangerTime = 5) => {
  const { days, hours, minutes, seconds } = formatted;

  if (completed) {
    return <Completionist />;
  }
  return (
    <p
      className={cs("ah-p-standard", {
        "ah-text-primary ":
          hours === "00" && minutes === "00" && seconds <= dangerTime
      })}
    >
      {days && days !== "00" ? days + ":" : ""}
      {hours && hours !== "00" ? hours + ":" : ""}
      {minutes + ":" + seconds}
    </p>
  );
};

const CountDownCmp = ({
  onComplete,
  dangerTime,
  duration,
  renderer
}) => {
  return (
    <Countdown
      date={Date.now() + duration * 1000}
      renderer={data =>
        renderer
          ? renderer(data, dangerTime)
          : defaultRenderer(data, dangerTime)
      }
      onComplete={onComplete}
      zeroPadTime={2}
    />
  );
};

export const CountDown = React.memo(CountDownCmp, (prevProps, nextProps)=> {
  return prevProps.duration === nextProps.duration
});
