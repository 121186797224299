import { call, takeEvery, put } from "redux-saga/effects";
import { getCampaignInfoApi } from "../campaignInfo/api";
import {
  GET_CAMPAIGN_INFO_DATA,
  GET_CAMPAIGN_INFO_SUCCESS
} from "./actionsTypes";

export function* getCampaignInfoData(invitationId) {
  try {
    const data = yield call(getCampaignInfoApi, invitationId.invitationId);
    yield put({
      type: GET_CAMPAIGN_INFO_SUCCESS,
      data
    });
  } catch (error) {}
}

export default function* invitationSaga() {
  yield takeEvery(GET_CAMPAIGN_INFO_DATA, getCampaignInfoData);
}
