import React, { Component } from "react";
import { connect } from "react-redux";
import Joyride from "react-joyride";
import { Question } from "../../components/Question";
import { TestQCM } from "../../components/Question/QCM/TestQCM";
import { TestLibre } from "../../components/Question/libre";
import { TestCoding } from "../../components/Question/Codage/TestCoding";
import QuestionFooter from "../../components/QuestionFooter";
import { questions } from "./data";
import { campaignInfo } from "./data";
import { t } from "../../i18n";
import { parse } from "query-string";
import { setCandidatResponse } from "../../redux/actions";
import { campaignTypes } from "../../shared/constants/invitationStatus"

const parsed = parse(window.location.search);

class CampaignMock extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      run: false,
      modalRunCode: false,
      steps: [
        {
          content: <h2>{t('StartAdventure')}</h2>,
          placement: "center",
          target: "body",
        },
        {
          content: <h2>{t('YourProgress')}</h2>,
          placement: "top",
          locale: { skip: t("Skip") },
          target: ".ah-tooltip",
        },
        {
          content: (
            <h4>{t('ResponseZone')}</h4>
          ),
          placement: "top-end",
          locale: { skip: t("Skip") },
          target: ".ah-content",
        },
        {
          content: (
            <h4>{t('ResponseTime')}</h4>
          ),
          placement: "top",
          locale: { skip: t("Skip") },
          target: ".ah-p-standard",
        },
        {
          content: (
            <h4>{t('GotoNextQuestion')}</h4>
          ),
          placement: "top",
          locale: { skip: t("Skip") },
          target: ".ah-button-next",
        },
      ],
      question: questions[0],
      campaignInfo: campaignInfo,
    };
    this.child = React.createRef();
  }

  componentDidMount = () => {
    this.setState({ run: true });
    localStorage.getItem('startTest') === null && localStorage.setItem('startTest', new Date().getTime());

    // add a step in joyride
    if(this.state.campaignInfo.type === campaignTypes.LIST_QUESTIONS) {
      this.setState(() => {
        const listQuestionStep = {
          content: <h2>{t('QuestionsList')}</h2>,
          placement: "top",
          locale: { skip: t("Skip") },
          target: ".ah-collapse-icon",
        };
  
        const newSteps = [...this.state.steps];
        newSteps.splice(2, 0, listQuestionStep);
        return {
          steps: newSteps
        }
      })
    }
  };

  toggleCodeModal = () => {
    this.setState(
      (prevState) => ({
        modalRunCode: !prevState.modalRunCode,
      }));
  };

  renderQuestion = () => {
    const question = this.state.question;
    switch (question.questionType) {
      case "QCM":
        return <TestQCM mock={question} />;
      case "OPEN_ENDED":
        return <TestLibre mock={question} />;
      case "CODE": 
        return <TestCoding 
                  mock={question}  
                  toggleCodeModal={this.toggleCodeModal}
                  modalRunCode={this.state.modalRunCode}
                />;
      default:
        return "";
    }
  };
  onNextQuestion = (time) => {
    this.setState({modalRunCode:false},()=> 
      {
        if (this.state.counter < questions.length - 1) {
          const newCounter = this.state.counter + 1;
          this.setState({ counter: newCounter, question: questions[newCounter] });
          this.props.setCandidatResponse({});
        } else {
          localStorage.removeItem("startTest");
          if ("close" in parsed) this.props.history.push("/Success");
          else this.props.history.push("/");
        }

      });
  };

  onPrevQuestion = () => {
    this.setState({modalRunCode:false},()=>
    {
      if (this.state.counter > 0) {
        const newCounter = this.state.counter - 1;
        this.setState({ counter: newCounter, question: questions[newCounter] });
        this.props.setCandidatResponse({});
      } else {
        localStorage.removeItem("startTest");
        if ("close" in parsed) this.props.history.push("/Success");
        else this.props.history.push("/");
      }
    }); 
  }
  onSelectQuestion = (index) => {
    this.setState({modalRunCode:false}, () => {
      const newCounter = index;
      this.setState({ counter: newCounter, question: questions[newCounter] });
      this.props.setCandidatResponse({});
    })
  }

  onDurationExpired = () => {
    this.setState({modalRunCode:false}, () => {
      localStorage.removeItem("startTest")
      if ("close" in parsed) this.props.history.push("/Success");
        else this.props.history.push("/");
    })
  }

  render() {
    const {
      counter,
      campaignInfo = {},
      question = {},
      loading,
      steps,
      run,
    } = this.state;

    const startTest = localStorage.getItem('startTest');
    const restTimeToEndCampaign = startTest === null ? campaignInfo.duration : campaignInfo.duration - ((new Date().getTime() - startTest) / 1000) 
    return (
      <React.Fragment>
        <Joyride
          locale={{
            back: t("Back"),
            close: t("Close"),
            last: t("Start"),
            next: t("Next"),
            skip: t("Skip"),
          }}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          run={run}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
          callback={this.handleJoyrideCallback}
        />
        <>
          <Question
            total={campaignInfo.totalQuestions || 10}
            current={counter + 1}
            content={campaignInfo?.title}
            campaignType={campaignInfo.type}
            isLoading={loading}
            questions={questions}
            onSelectQuestion={this.onSelectQuestion}
          >
            {this.renderQuestion()}
          </Question>
          <QuestionFooter 
            campaignInfo={campaignInfo}
            restTimeToEndCampaign={restTimeToEndCampaign}
            questionDuration={question.duration}
            loading={loading}
            onNextQuestion={this.onNextQuestion}
            onPrevQuestion={this.onPrevQuestion}
            onComplete={this.onNextQuestion}
            onDurationExpired={this.onDurationExpired}
            current={counter+1}
          />
        </>
      </React.Fragment>
    );
  }
}
export default connect(null, {
  setCandidatResponse,
})(CampaignMock);
