import axiosInterceptor from "../../shared/utils/axios-interceptor";
import Axios from "axios";

export const getAuthenticationApi = token => {
  if (process.env.REACT_APP_MOCK_DATA) {
    return Axios.get("./mock/invitation.json");
  }
  return axiosInterceptor.get(`/invitations/link/${token}`);
};

export const getLogoRecruiterApi = data => axiosInterceptor.get(data);
