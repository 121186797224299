import { call, takeEvery, put } from "redux-saga/effects";
import { get } from "lodash";
import {
  putCandidateApi,
  getCandidateApi,
  getExperienceApi,
  postCandidateApi,
  postInvitationCandidateApi,
  gradeCandidateApi
} from "../candidat/api";
import {
  PUT_CANDIDAT_DATA,
  GET_CANDIDATE_DATA,
  GET_CANDIDATE_ERROR,
  PUT_CANDIDAT_SUCCESS,
  PUT_CANDIDAT_ERROR,
  GET_EXPERIENCE_DATA,
  GET_EXPERIENCE_ERROR,
	POST_CANDIDATES,
	POST_INVITATION_CANDIDATE,
  GRADE_CANDIDATE
} from "./actionsTypes";
import { getCandidateDone, getExperienceDone} from "./action";

export function* putCandidateData({ payload }) {
 const { candidat, success, error } = payload;
  try {
    const response = yield call(putCandidateApi, candidat);
    yield put({ type: PUT_CANDIDAT_SUCCESS, response });
    if ([200, 201].includes(response.status)) {
			if (success) success();
		} else {
			if (error) error();
		}
  } catch (error) {
    yield put({ type: PUT_CANDIDAT_ERROR, error });
  }
}

export function* getCandidateData(invitationId) {
  try {
    const data = yield call(getCandidateApi, [invitationId.invitationId]);
    yield put(getCandidateDone(data));
  } catch (error) {
    yield put({ type: GET_CANDIDATE_ERROR, error });
  }
}

export function* getExperienceData() {
  try {
    const data = yield call(getExperienceApi);
    yield put(getExperienceDone(data));
  } catch (error) {
    yield put({ type: GET_EXPERIENCE_ERROR, error });
  }
}

function* postInvitationCandidateSaga({ payload }) {
	const { invitation, email, success, error } = payload;
	try {
		const response = yield call(
			postInvitationCandidateApi,
			invitation,
			email
		) || {};
		if ([200, 201].includes(response.status)) {
			if (success) success();
		} else {
			if (error) error(get(response, "data.message"));
		}
	} catch (err) {
		if (error) error(err);
	}
}

function* postCandidateSaga({ payload }) {
	const { candidate, link, success, error } = payload;
	try {
		const response = yield call(postCandidateApi, candidate, link) || {};
		yield put(getCandidateDone(response.data));
		if ([200, 201].includes(response.status)) {
			if (success) success(response.data); 
		} else {
			if (error) error(get(response, "data.message"));
		}
	} catch (err) {
		if (error) error(err);
	}
}

function* gradeCanidateSaga({ payload }) {
  let { invitation } = payload;

  try {
    yield call(gradeCandidateApi, invitation);
  } catch (err) {
    console.log(err);
  }
}
 
export default function* actionWatcherCandidate() {
  yield takeEvery(PUT_CANDIDAT_DATA, putCandidateData);
  yield takeEvery(GET_CANDIDATE_DATA, getCandidateData);
  yield takeEvery(GET_EXPERIENCE_DATA, getExperienceData);
  yield takeEvery(POST_CANDIDATES, postCandidateSaga);
  yield takeEvery(POST_INVITATION_CANDIDATE, postInvitationCandidateSaga);
  yield takeEvery(GRADE_CANDIDATE, gradeCanidateSaga);
}
