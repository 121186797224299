import React from "react";
import cs from "classnames";

export const Loader = ({ isOverlay }) => (
  <div className={cs({ "ah-overlay": isOverlay })}>
    <div className="ah-loading">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
