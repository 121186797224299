import instance from "../../shared/utils/instance";
import { GET_QUESTION_ENDPOINT } from "./endpoints";
import Axios from "axios";

export const getEndpointFromType = type => {
  switch (type) {
    case "QCM":
      return "/candidate-answer-qcms";
    case "OPEN_ENDED":
      return "/candidate-answer-open-endeds";
    default:
      return "/candidate-answe-codes";
  }
};

export const getQuestionApi = idInvitation => {
  if (process.env.REACT_APP_MOCK_DATA) {
    return Axios.get("./mock/question2.json");
  }
  return instance.get(GET_QUESTION_ENDPOINT + idInvitation);
};

export const postRunCodeApi = data => {
  return instance.post(`/codeexecution/${data.data.codeId}`, data.data);
};