import React from "react";
import { getQuerySelector } from '@redux-requests/core';
import { useSelector } from 'react-redux';

import { isEmpty, isNull } from "lodash";
 
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { t } from "../../i18n";
import { CONTACT_US, server_uri_api } from "shared/config/constants";
import { parse } from "query-string";
import { GET_CURRENT_ROOT_ENTITY } from "redux/rootEntity/actionsTypes";
import { DOWNLOAD_LOGO_ENTITY } from "redux/rootEntity/endpoints";

const SuccessPage = () =>{
	 
	const campaignInfo = useSelector(
		({campaignLink, authentication}) => 
			parse(window.location.search).reg
			? campaignLink.campaign.campaignDTO
			: authentication.campaign,
	);

	const emailRecruiter = useSelector(
		({ authentication, campaignLink })=> 
			campaignLink.campaign.campaignLinkDTO?.emailRecruiter
			|| authentication.invitation?.emailRecuiter
	);

	const currentRootEntity = useSelector(getQuerySelector({ type: GET_CURRENT_ROOT_ENTITY })).data;

	const mode = useSelector(({authentication})=> authentication.mode);

	const	onCloseCnx = () => {
		if (mode === "AT") {
			setTimeout(() => {
			  // eslint-disable-next-line no-undef
				javaConnector.shutDown();
			}, 5000);
		}
	};
	return (
		<div className="ah-success-page text-center pb-6">
			<div className="overflow-auto">
				{isEmpty(currentRootEntity) ||
				 isNull(currentRootEntity.logoEntity) ?
				   "":
					 <img
							src={`${server_uri_api}${DOWNLOAD_LOGO_ENTITY}/${currentRootEntity.name}/${currentRootEntity.logoEntity}`}
							alt="logo"
							className="ah-logo-success "
				   />
				 }
				<h3 className="pb-4 pt-5">{campaignInfo && campaignInfo.title}</h3>
				<span className="ah-icon-success pb-4 ">
				<FontAwesomeIcon icon={faCheckCircle} />
				</span>

				{parse(window.location.search).reg ? (
					<>
						<h4 className="ah-success-text1 pt-5 pb-4">
							{t("Thank_you_for_your_registration")}
						</h4>
					</>
				) : (
					<h4 className="ah-success-text1 pt-5 pb-4">
						{t("Thank_you_for_taking_this")}. <br />
						{t("your_ansewers_were_well_ent")}.
					</h4>
				)}
				<i>
					<h4 className=" ah-success-text2 pt-4">
						{t("You_have_questions_dont_hesitate_to_contact_us_on")}
						{" : "}
						{emailRecruiter || CONTACT_US}
					</h4>
		    </i>
				{onCloseCnx()}
			</div>
		</div>
	);
}
export default SuccessPage;
 