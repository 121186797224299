import React, { Component, Fragment } from "react";
import { CardHeader, Row, Col, CardBody } from "reactstrap";
import { Step } from "../../components/Step";
import Sidebar from "../../components/Sidebar";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Loader } from "../Loader";

import { isEmpty, isNull } from "lodash";

import { server_uri_api } from "shared/config/constants";

import { DOWNLOAD_LOGO_ENTITY } from "redux/rootEntity/endpoints";
import { campaignTypes } from "../../shared/constants/invitationStatus";

export class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: undefined,
    };
  }

  startCounter = () => {
    this.setState({ startTime: Date.now() });
  };

  render() {
    const {
      current,
      total,
      children,
      isLoading,
      currentRootEntity,
      content,
      questions,
      onSelectQuestion,
      campaignType,
    } = this.props;

    return (
      <Fragment>
        {isLoading && <Loader isOverlay />}
        <CardHeader className="ah-cardheader pb-0">
          <Row>
            <Col xs="12" sm="6">
              <p className="ah-p2-standard m-0 ah-compagne-title">
                {content}
              </p>
            </Col>
            <Col xs="6" className="text-right d-flex flex-row justify-content-end align-items-center">
              {isEmpty(currentRootEntity) ||
                isNull(currentRootEntity.logoEntity) ?
                "":
                <img
                    src={`${server_uri_api}${DOWNLOAD_LOGO_ENTITY}/${currentRootEntity.name}/${currentRootEntity.logoEntity}`}
                    alt="logo"
                    className="ah-question-logo mb-1"
                />
              }
              {campaignType === campaignTypes.LIST_QUESTIONS && <Sidebar questions={questions} current={current} onSelectQuestion={onSelectQuestion}/>}
            </Col>
          </Row>

          <Step current={current} total={total} />
        </CardHeader>
        <CardBody className="ah-cardbody">{!isLoading && children}</CardBody>
        
      </Fragment>
    );
  }
}
Question.defaultProps = {
  onNextQuestion: undefined,
};
Question.propTypes = {
  onNextQuestion: PropTypes.func,
};

export default reduxForm({
  form: "question",
})(Question);
