import {
  PUT_CANDIDAT_DATA,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE_DATA,
  GET_CANDIDATE_ERROR,
  GET_EXPERIENCE_SUCCESS,
  GET_EXPERIENCE_DATA,
  GET_EXPERIENCE_ERROR,
	POST_CANDIDATES,
	POST_INVITATION_CANDIDATE,
  GRADE_CANDIDATE
} from "./actionsTypes";

export const putCandidate = ( candidat, success, error) => {
  return {
    type: PUT_CANDIDAT_DATA,
    loading: true,
    payload: { candidat, success, error }, 
  };
};

export const getCandidate = (invitationId) => ({
  type: GET_CANDIDATE_DATA,
  invitationId,
});

export const getCandidateDone = (data) => ({
  type: GET_CANDIDATE_SUCCESS,
  data,
  loading: false,
});
export const getCandidateError = (data) => ({
  type: GET_CANDIDATE_ERROR,
  data,
  loading: false,
});

export const getExperience = () => ({
  type: GET_EXPERIENCE_DATA,
});

export const getExperienceDone = (data) => ({
  type: GET_EXPERIENCE_SUCCESS,
  data,
  loading: false,
});
export const getExperienceError = (data) => ({
  type: GET_EXPERIENCE_ERROR,
  data,
  loading: false,
});

export const postInvitationCandidate = (invitation, email, success, error) => ({
	type: POST_INVITATION_CANDIDATE,
	payload: { invitation, email, success, error },
});

export const postCandidate = (candidate, link, success, error) => ({
	type: POST_CANDIDATES,
	payload: { candidate, link, success, error },
}); 
  
export const gradeCandidate = (invitation, success) => ({
  type: GRADE_CANDIDATE,
  payload: { invitation, success },
});
