import React, { Component } from "react";
import PropTypes from "prop-types";
import { t } from "../../../i18n";
import classNames from "classnames";

export class Textarea extends Component {
  static propTypes = {
    countLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    countShow: PropTypes.bool,
    onValueChange: PropTypes.func
  };

  static defaultProps = {
    countLimit: "250",
    countShow: true
  };

  state = {
    count: 0
  };

  handleChange = e => {
    this.setState({
      count: e.target.value.length
    });

    this.props.onValueChange(e.target.value);
  };
  renderCount() {
    const { countLimit } = this.props;

    const textareaClass = classNames("ah-textarea__count", {
      "ah-textarea__count--error": this.state.count >= countLimit
    });

    return (
      <span className={textareaClass}>
        {this.state.count}
        {countLimit > 0 && ` ${t("on")} ${countLimit} ${t("characters")}`}
      </span>
    );
  }
  render() {
    const {
      countLimit,
      countShow,
      input,
      onValueChange,
      className,
      ...restProps
    } = this.props;

    return (
      <div className="ah-textarea">
        <textarea
          className={`form-control ah-textArea ${className}`}
          maxLength={countLimit}
          onChange={this.handleChange}
          {...restProps}
          {...input}
        />
        {countShow && <span> {this.renderCount()}</span>}
      </div>
    );
  }
}
