export const PUT_CANDIDAT_DATA = "PUT_CANDIDAT_DATA";
export const PUT_CANDIDAT_SUCCESS = "PUT_CANDIDAT_SUCCESS";
export const PUT_CANDIDAT_ERROR = "PUT_CANDIDAT_ERROR";

export const GET_CANDIDATE_DATA = "GET_CANDIDATE_DATA";
export const GET_CANDIDATE_SUCCESS = "GET_CANDIDATE_SUCCESS";
export const GET_CANDIDATE_ERROR = "GET_CANDIDATE_ERROR";

export const GET_EXPERIENCE_SUCCESS = "GET_EXPERIENCE_SUCCESS";
export const GET_EXPERIENCE_DATA = "GET_EXPERIENCE_DATA";
export const GET_EXPERIENCE_ERROR = "GET_EXPERIENCE_ERROR";

export const POST_CANDIDATES = "POST_CANDIDATES";
export const POST_CV = "POST_CV";

export const POST_INVITATION_CANDIDATE = "POST_INVITATION_CANDIDATE";
export const GRADE_CANDIDATE = "GRADE_CANDIDATE";
