import React, { Fragment } from "react";
import { Progress } from "reactstrap";

export const Step = ({ current, total }) => {
  const value = (current / total) * 100;
  return (
    <Fragment>
      <div
        className="ah-tooltip"
        style={{
          left: value + "%"
        }}
      >
        {`${current}/${total}`}
      </div>

      <Progress
        color="danger"
        value={value}
        className="ah-progress"
        aria-valuemin="0"
        id="Tooltip"
      />
    </Fragment>
  );
};
