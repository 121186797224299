import {
  GET_RANDOM_QUESTION,
  GET_RANDOM_QUESTION_SUCCESS,
  SET_USER_RESPONSE,
  POST_RUN_CODE,
  GET_TEST_CASE_RESULTS,
  SET_ANSWERS
} from "./actionsTypes";
import { RECEIVE_AUTHENTICATION_DATA } from "../authentication/actionsType";

const initialState = {
  data: [],
  questions: {},
  counter: 0,
  id: null,
  loading: false,
  current: {},
  resultTestCases: {},
  answers: []
};

// eslint-disable-next-line
export default (state = initialState, payload) => {
  switch (payload.type) {
    case SET_ANSWERS:
      return {
        ...state,
        answers: payload.data
      };
    case POST_RUN_CODE:
      return {
        ...state
      };
    case GET_RANDOM_QUESTION_SUCCESS:
      return {
        ...state,
        current: payload.data.data,
        counter: ++state.counter,
        loading: false
      };
    case RECEIVE_AUTHENTICATION_DATA:
      return {
        ...state,
        counter: payload.authData.currentQuestion,
        currentQuestion: payload.authData.currentQuestion
      };

    case SET_USER_RESPONSE:
      return {
        ...state,
        candidatResponse: payload.payload.data
      };
    case GET_RANDOM_QUESTION:
      return {
        ...state,
        loading: true
      };
    case GET_TEST_CASE_RESULTS:
      return {
        ...state,
        resultTestCases: payload.data
      };
    default:
      return state;
  }
};
