import React from "react";
import { Input } from "reactstrap";

import cs from "classnames";
import { FormFields } from "../FormFields";

export const ValidatedInputCv = (props) => {
	const {
		input,
		type,
		inputClassName,
		disabled,
		autoComplete,
		id,
		meta: { touched, error },
		min,
		maxLength,
		max,
		placeholder,
		inputValue,
	} = props;

	return (
		<FormFields {...props}>
			<Input
				disabled={disabled}
				{...input}
				type={type}
				id={id}
				autoComplete={autoComplete}
				className={cs(inputClassName, { "is-invalid": touched && error })}
				min={min}
				maxLength={maxLength ? maxLength : 254}
				max={max}
				placeholder={placeholder}
				value={inputValue}
			/>
		</FormFields>
	);
};
