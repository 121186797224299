import {
	GET_CAMPAIGN_LINK_DATA,
	GET_CAMPAIGN_LINK_SUCCESS,
	GET_CAMPAIGN_LINK_ERROR,
} from "./actionsTypes";

const initialState = {
	data: [],
	campaign: {},
	loading: true,
	tags: [],
};

// eslint-disable-next-line
export default (state = initialState, payload) => {
	switch (payload.type) {
		case GET_CAMPAIGN_LINK_DATA:
			//clearLocalStorage();
			return { ...state, loading: true };
		case GET_CAMPAIGN_LINK_SUCCESS:
			return {
				...state,
				campaign: payload.data,
				tags: payload.data.campaignLinkDTO.tags,
				loading: false,
			};
		case GET_CAMPAIGN_LINK_ERROR:
			return { ...state, campaign: { error: true }, loading: false };
		default:
			return state;
	}
};