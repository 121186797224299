import React from "react";
import { useSelector } from "react-redux";

import { parse } from "query-string";
import { t } from "../../i18n";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/logo.png";
import { CONTACT_US } from "../../shared/config/constants";

const parsed = parse(window.location.search);
 
export const Expired = () =>{

	const emailRecruiter = useSelector(
		({campaignLink, authentication}) => 
			campaignLink.campaign.campaignLinkDTO?.emailRecruiter ||
			authentication.invitation?.emailRecuiter
	);

	return (
		<div className="ah-container">
			<img
				src={logo}
				alt={t("Novelis_logo")}
				className="ah-nvlogo img-fluid mx-auto"
			/>
			<h3 className="ah-sessionexph3">{t("Oops_othing_to_see_here!")}</h3>
      <FontAwesomeIcon
				icon={faExclamation}
				size="10x"
				className="ah-exclamation"
			/>
			<p className="ah-paragraph">
			  {t("Unfortunately_you_have_exceeded_the_campaign_deadline")}.
			  <br />
				{parsed.q || parsed.reg ? (
					<span className="ah-contact-us">
						{t("Please_contact_us_on")} : {emailRecruiter || CONTACT_US}
					</span>
				) : null}
			</p>
		</div>
	);
}

