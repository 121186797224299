import React from "react";
import { Button } from "reactstrap";
import classnames from "classnames";

const StateButton = ({ children, isLoading, className, ...rest }) =>{
    return (
      <span>
        <Button
          className={`ah-button btn-multiple-state  ${className}  ${classnames({
            "show-spinner": isLoading
          })}`}
          disabled={isLoading}
          {...rest}
        >
          <span className="spinner d-inline-block">
            <span className="bounce1" />
            <span className="bounce2" />
            <span className="bounce3" />
          </span>
          <span className="label">{children}</span>
        </Button>
      </span>
    );
  
}

export default StateButton;
