import {
  RECEIVE_AUTHENTICATION_DATA,
  RECEIVE_AUTHENTICATION_ERROR,
  REQUEST_AUTHENTICATION,
  SET_MODE,
  GET_RECRUITER_LOGO,
  SET_RECRUITER_LOGO,
  GET_RECRUITER_LOGO_ERROR
} from "./actionsType";

const initialState = {
  isAuthenticated: false,
  mode: null,
  state: { error: undefined, loading: true },
  candidate: {},
  campaign: {},
  invitation: {},
  instructions: {},
  restTimeToStart: null,
  restTimeToEnd: null,
  currentTime: null,
  recruiterLogo: {
    loading: false,
    error: undefined,
    data: {}
  }
};

// Reducer

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_AUTHENTICATION:
      clearLocalStorage();
      return {
        ...state,
        state: { error: undefined, loading: true }
      };
    case RECEIVE_AUTHENTICATION_ERROR:
      return {
        ...state,
        state: { error: action.authData, loading: false },
        invitation: { error: true }
      };
    case RECEIVE_AUTHENTICATION_DATA:
      return {
        ...state,
        isAuthenticated: true,
        candidate: action.authData.candidateDTO,
        invitation: action.authData.invitationDTO,
        campaign: action.authData.campaignDTO,
        restTimeToStart: action.authData.restTimeToStart,
        restTimeToEnd: action.authData.restTimeToEnd,
        currentTime: action.authData.currentTime,
        restTimeToEndCampaign: action.authData.restTimeToEndCampaign,
        state: { error: undefined, loading: false }
      };
    case SET_MODE:
      return {
        ...state,
        mode: action.payload
      };
    case GET_RECRUITER_LOGO:
      return {
        ...state,
        recruiterLogo: {
          loading: true,
          error: undefined,
          data: {}
        }
      };
    case GET_RECRUITER_LOGO_ERROR:
      return {
        ...state,
        recruiterLogo: {
          loading: false,
          error: action.payload,
          data: "/static/media/logo.a3ef91c2.png"
        }
      };
    case SET_RECRUITER_LOGO:
      return {
        ...state,
        recruiterLogo: {
          loading: false,
          error: undefined,
          data: action.payload
        }
      };

    default:
      return state;
  }
};

const clearLocalStorage = () => {
  localStorage.clear();
};