import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { get, isArray } from "lodash";
import { t } from "i18n";

import { fieldsType } from "shared/constants/fieldsType";

import { ValidatedInput } from "components/form/Input/ValidatedInput";
import { ValidatedSelected } from "components/form/Input/ValidatedSelected";
import Checkbox from "components/form/Checkbox";
import StateButton from "components/StateButton";
import { ButtonG } from "components/form/Button";
import {
	required,
	isValidName,
} from "components/form/validationRules";

const fieldComponent = {
	input: ValidatedInput,
	select: ValidatedSelected,
	checkbox: Checkbox
};

const validation = {
	required: required,
};

const CustomFieldsFom = ({
	customFields,
	handleSubmit,
	moreSteps,
	previousForm,
	isLoadingAddingCandidate,
	initialValues
}) => { 
	return (
		<form onSubmit={handleSubmit} className="pl-5 pr-5 ah-home-validation">
			
			{customFields && Object.keys(customFields).map((type, index) => {
				const ValidateField = fieldComponent[type];
				const ValidateA = validation[customFields[type].validate];
				return(
				isArray(customFields[type]) ?
				customFields[type].map((field, id) =>{
					const Validate = validation[field.validate];
					return (
						<Field
							key={id}
							name={field.name}
							component={ValidateField}
							label={field.title}
							type={field.type}
							id={`${field}-${id}`}
							validate={
									field.type === "text"
									? [isValidName, Validate].filter(
											(validateItem) => validateItem
										)
									: Validate
							}
							options={field.options}
							className={type==="checkbox" ? "mt-4 ml-4 mb-4" : "mt-2"}
						/>
					);

				})
				:
				<Field
					key={index}
					name={customFields[type].name}
					component={ValidateField}
					label={customFields[type].title}
					type={customFields[type].type}
					id={`${type}-${index}`}
					validate={
						customFields[type].type === "text"
							? [isValidName, ValidateA].filter(
									(validateItem) => validateItem
								)
							: ValidateA
					}
					options={customFields[type].options}
					className={type==="checkbox" ? "mt-4 ml-4 mb-4" : "mt-2"}
				/>
				)
			})}
			<div className="text-right">
				<ButtonG
					className="ah-btn-Submit mr-4"
					color="primary"
					size="lg"
					onClick={() => previousForm()}
				>
					{t("Previous")}
				</ButtonG> 
				<StateButton type="submit" color="primary" size="lg" isLoading={isLoadingAddingCandidate}>
					{moreSteps ? t("Next") : t("Submit")}
				</StateButton>
			</div>
		</form>
	);
};
const mapStateToProps = (state, props) => {
	const initialValues = {};
	props.candidat?.customField && Object.keys(props.customFields).forEach((type) => {
		if (type === fieldsType.SELECT) {
			isArray(props.customFields[type]) ?
			props.customFields[type].forEach((field) =>{
				initialValues[field.name] = get(props.candidat?.customField[field.name],
					"value",
					props.candidat?.customField[field.name]
				);
			})
		:
			initialValues[props.customFields[type].name] = 
				get(props.candidat?.customField[props.customFields[type].name],
					"value",
					props.candidat?.customField[props.customFields[type].name]
				)
		}else{
			isArray(props.customFields[type]) ?
			props.customFields[type].forEach((field) =>{
				initialValues[field.name] = props.candidat?.customField
				? props.candidat?.customField[field.name]
				: undefined
			})
			:
				initialValues[props.customFields[type].name] = props.candidat?.customField
				? props.candidat?.customField[props.customFields[type].name]
				: undefined
		}

	});
	return {
		initialValues: initialValues,
	};
};

export default connect(mapStateToProps)(
	reduxForm({
		form: "customFielsForm",
	})(CustomFieldsFom)
);