import { call, put, takeLatest } from "redux-saga/effects";

import { REQUEST_INSTRUCTION_DATA } from "./actionsTypes";
import { receiveInstructionData } from "./action";
import { getInstructionsApi } from "./api";

function* getInstructionsData({ payload }) {
  try {
    const { campaignId } = payload;
    const data = yield call(getInstructionsApi, campaignId);
    yield put(receiveInstructionData(data));
  } catch (error) {}
}

export default function* instructionSaga() {
  yield takeLatest(REQUEST_INSTRUCTION_DATA, getInstructionsData);
}
