import axios from "axios";

import { TIMEOUT } from "../config/constants";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
  timeout: TIMEOUT,
  headers: { Pragma: "no-cache" }
});
const onRequestSuccess = config => {
  const token =
    localStorage.getItem("authenticationToken") ||
    sessionStorage.getItem("authenticationToken");
  if (token) {
    config.headers["Authorization-candidate"] = `Bearer ${token}`;

  }
  return config;
};

instance.interceptors.request.use(onRequestSuccess);
export default instance;
