import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import store from "./shared/store/store";
import { Container } from "./layout/container";
import Footer from "./layout/footer";

import "bootstrap/dist/css/bootstrap.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/sass/main.scss";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="ah-main-container h-100">
          <Container />
          <Footer />
        </div>
      </Provider>
    );
  }
}
export default App;
