import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../lang/en/translation.json";
import translationFR from "../lang/fr/translation.json";

const resources = {
	"en-US": {
		translation: translationEN,
	},
	"fr-FR": {
		translation: translationFR,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: "fr-FR",

  keySeparator: false,

  interpolation: {
    escapeValue: false
  }
});

export const t = (key, options) => {
  return options ? i18n.t(key, options) : i18n.t(key);
};

export { i18n };
