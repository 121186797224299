import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { FormFields } from "../FormFields";
import cs from "classnames";
import { fr } from "date-fns/locale";
import i18n from "i18next";
import { t } from "i18n";

registerLocale("fr-FR", fr);

const DatePickerCmp = (props) => {
	const {
		input,
		className,
		inputClassName,
		meta: { touched, error },

		...rest
	} = props;
	return (
		<FormFields {...props}>
			<DatePicker
				locale={i18n.language === "en-US" ? undefined : i18n.language} //to avoid warning (default locale is en-US)
				className={cs(inputClassName, "form-control", {
					"is-invalid": touched && error,
				})}
				selected={Date.parse(input.value)}
				onChange={input.onChange}
				showTimeSelect
				timeFormat="HH:mm"
				timeIntervals={15}
				dateFormat="MMMM d, yyyy h:mm aa"
				timeCaption="Time"
				placeholderText={t("Choose date and time")}
				autoComplete="off"
				{...rest}
			/>
		</FormFields>
	);
};

export default DatePickerCmp;
