import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ButtonG } from "../form/Button";
import { t } from "../../i18n";
import { CONTACT_US } from "../../shared/config/constants";
import { campaignTypes } from "../../shared/constants/invitationStatus";

class Instructions extends React.Component {
  startTest = () => {
    this.props.campaignType === campaignTypes.LIST_QUESTIONS ? 
      this.props.history.push("/test/1") : 
      this.props.history.push("/test");
  };

  showInstruction(item, mail) {
    return (
      <li
        key={item.id}
        dangerouslySetInnerHTML={{
          __html: item.instruction.replace(
            new RegExp("contactUS", "gi"),
            mail ? mail : CONTACT_US
          ),
        }}
      />
    );
  }

  render() {
    const results = this.props.instrctions.data || [];
    const instructions = results.map((item) =>
      this.showInstruction(item, this.props.invitation.emailRecuiter)
    );
    return (
      <div>
        <span className="ah-title-instructions">{t("Instructions")}:</span>
        <ol className="ah-text-instructions">{instructions}</ol>
        <div className="text-center">
          <ButtonG
            type="submit"
            color="primary"
            size="lg"
            className="ah-button"
            onClick={this.startTest}
          >
            {t("Start")}
          </ButtonG>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  instrctions: state.instructions.data,
  invitation: state.authentication.invitation,
  campaignType: state.authentication.campaign.type
});

export default connect(mapStateToProps)(withRouter(Instructions));
