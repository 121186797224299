import React, { useEffect, useState } from "react";
import { getQuery } from "@redux-requests/core";
import { Row, Col } from "reactstrap";
import { CSSTransition } from "react-transition-group";
import Login from "./Login";
import CompagneInfo from "./CompagneInfo";
import Instruction from "../../components/Instructions";
import logo from "../../assets/img/logo.png";
import { connect } from "react-redux";
import { putCandidate } from "../../redux/candidat/action";
import { requestInstructionData } from "redux/actions";

import { GET_CURRENT_ROOT_ENTITY } from "redux/rootEntity/actionsTypes";

const HomeConfirmation = (props) => {
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		if (props.currentRootEntity) {
			props.requestInstructionData(props.currentRootEntity?.id);
		}
	}, [props.currentRootEntity]); // eslint-disable-line
 
	const handler = () => {
		setIsEdit(true);
	};

	const submitCandidate = (values) => {
		props.putCandidate(values);
		handler();
	};

	return (
		<div className="container-fluid h-100  ah-transition">
			<Row className="ah-row">
				<Col xs="12" sm={6} className="border-right">
					<CompagneInfo
						onComplete={() => {
							props.history.push("/expired");
						}}
					/>
				</Col>
				<Col xs="12" sm={6}>
					<CSSTransition classNames="ah-fade" in={isEdit} timeout={300}>
						{!isEdit ? <Login onSubmit={submitCandidate} /> : <Instruction />}
					</CSSTransition>
				</Col>
				<div xs="6">
					<div className="ah-arrow-Style d-none d-sm-block" />
				</div>
				<div xs="6">
					<img src={logo} className="col-2 ah-logo-home" alt="logo" />
				</div>
			</Row>
		</div>
	);
};

const mapStateToProps = (state) => ({
	campaign: state.authentication.campaign,
	currentRootEntity: getQuery(state, {
		type: GET_CURRENT_ROOT_ENTITY,
	}).data,
});

export default connect(mapStateToProps, {
	putCandidate,
	requestInstructionData,
})(HomeConfirmation);
