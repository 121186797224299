import React, { Component } from "react";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";

import { Textarea } from "../../form/TextArea";
import { t } from "../../../i18n";
import { setCandidatResponse } from "../../../redux/questions/action";
import QuestionContent from "components/QuestionContent";

class TestLibreCmp extends Component {
  onChange = (newValue) => {
    this.props.setCandidatResponse({ submittedAnswer: newValue, isChanged: true });
  };

  render() {
    const { content } = isEmpty(this.props.question)
      ? this.props.mock
      : this.props.question;

    return (
      <form className="ah-content mt-3">
        <div
          className="ah-50 overflow-auto"
        >
          <QuestionContent content={content} />
          <Textarea
            onValueChange={this.onChange}
            value={
              get(this.props, "candidatResponse.submittedAnswer", "") || ""
            }
            rows="8"
            name="reponse"
            id="reponse"
            placeholder={t("Your_answer")}
          />
        </div>
      </form>
    );
  }
}
const mapStateToPros = ({ questions }) => {
  return {
    candidatResponse: questions.candidatResponse,
  };
};

export const TestLibre = connect(mapStateToPros, { setCandidatResponse })(
  TestLibreCmp
);
