import instance from "../../shared/utils/instance";

export const putCandidateApi = (body) => instance.put(`/profil-candidate`, body);

export const getCandidateApi = (invitationId) =>
  instance
    .get(`/invitations/candidate/${invitationId}`)
    .then((response) => response.data);

export const getExperienceApi = () =>
  instance.get(`/ref-experiences`).then((response) => response.data);

export const postInvitationCandidateApi = (invitation, email) =>
	instance.post(`/generateInvitationWithCandidate/${email}`, invitation);

export const postCandidateApi = (candidate, link) =>
	instance.post(`/candidates/link/${link}`, candidate);

export const gradeCandidateApi = (invitation) =>
  instance.post("/confirmation/score-candidate", invitation);
