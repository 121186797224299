import React, { Component } from "react";
import { Input as InputRS, Label, FormGroup, Col, Row } from "reactstrap";

export class Input extends Component {
  render() {
    const { input, label, ...rest } = this.props;
    return (
      <FormGroup className="ah-input">
        <Row className="border-bottom">
          <Col xs={4} sm={3}>
            <Label className="ah-lbl-input">{label}</Label>
          </Col>
          <Col xs={8} sm={9}>
            <InputRS
              className=" ah-input-border border-0 form-control "
              {...input}
              {...rest}
            />
          </Col>
        </Row>
      </FormGroup>
    );
  }
}
