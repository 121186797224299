import React from "react";
import { reduxForm, Field } from "redux-form";
import { Row, Col, Alert } from "reactstrap";
import { t } from "i18n";
import { ButtonG } from "components/form/Button";
import { required } from "components/form/validationRules";
import DatePicker from "components/form/DatePickerCmp";
import TimeZone from "components/TimeZone";
import StateButton from "components/StateButton";

const ChooseTestDate = (props) => {
	const { messageError,
			previousForm, 
			handleSelectChange, 
			handleSelectChangeStartDate,
			isLoadingCreateInvitation
	      } = props;
	return (
		<>
			<Row className="mb-4 pl-5 pr-5">
				<Col>
					<div className="h3">{t("Choose_the_test_time")}</div>
					<p>{t("you_have_choose_test_time")}</p>
				</Col>
			</Row>
			<Row className="pl-5 pr-5">
				<Col>
					<form onSubmit={props.handleSubmit} className=" ah-home-validation">
						<Field
							className="mt-4"
							name="timeZone"
							id="invitation-timeZone"
							component={TimeZone}
							labelClassName="mb-0"               
							placeholder="invitation-timeZone" 
							onChange={(e) => handleSelectChange(e)}
							validate={required}    
						/>

						<Field
							className="mt-4"
							name="startDate"
							id="invitation-endDate"
							component={DatePicker}
							onChange={(e) => handleSelectChangeStartDate(e)}
							labelClassName="mb-0"
							type="datetime-local"
							validate={required}
						/>
						{messageError && (
							<div>
								<Alert color="danger" xs="12">
									{messageError}
								</Alert>
							</div>
						)}

						<div className="text-right">
							<ButtonG
								className="ah-btn-Submit mr-4"
								color="primary"
								size="lg"
								onClick={() => previousForm()}
							>
								{t("Previous")}
							</ButtonG>
							<StateButton
								className="ah-btn-Submit "
								type="submit"
								color="primary"
								size="lg"
								isLoading={isLoadingCreateInvitation}
							>
								{t("Submit")}
							</StateButton>
						</div>
					</form>
				</Col>
			</Row>
		</>
	);
};

export default reduxForm({
	form: 'chooseTimeForm',
	enableReinitialize: true
  })(ChooseTestDate)


