import {
  REQUEST_INSTRUCTION_DATA,
  RECEIVE_INSTRUCTION_DATA,
} from "./actionsTypes";

export const requestInstructionData = (campaignId) => ({
  type: REQUEST_INSTRUCTION_DATA,
  payload: { campaignId },
});
export const receiveInstructionData = (instructions) => ({
  type: RECEIVE_INSTRUCTION_DATA,
  instructions,
});
