import { campaignTypes } from "../../shared/constants/invitationStatus";
export const questions = [
  {
    id: 9,
    title: "Problème d'addition",
    isPublic: true,
    score: 10,
    content:
      '<p><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(0, 0, 0);">Description du problème&nbsp;</strong></p><p>Créez une fonction qui prend deux nombres comme arguments et renvoie leur somme.&nbsp;</p><p><strong style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">Spécifications des données d\'entrée (Input)&nbsp;</strong></p><p><span style="background-color: rgb(255, 255, 255);">Deux nombres (x, y) </span></p><p><strong style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">Exemple Input/Output&nbsp;</strong></p><p>addition(3, 2) ➞ 5</p><p>addition(-3, -6) ➞ -9</p><p>addition(7, 3) ➞ 10<strong style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">﻿</strong></p>',
    isDelete: false,
    questionType: "CODE",
    duration: 90,
    refLevelId: 1,
    refTechnologyId: 1,
    problemDescription: null,
    functionReturnType: "Integer",
    constraint: "string",
    defaultCodes: [
      {
        id: 7,
        boilerplate:
          'import java.util.*;\r\n// Pour deboguer votre code\r\n// System.err.println("deboguer");\r\n\r\n\r\npublic class Solution {\r\n\r\n  // NE PAS ENLEVER CECI !\r\n  public static void main(String[] args) {\r\n    int x = Integer.parseInt(args[0]);\r\n    int y = Integer.parseInt(args[1]);\r\n    \r\n    System.out.println(Addition(x, y));\r\n    \r\n  }\r\n  \r\n  \r\n  private static int Addition(int a, int b) {\r\n    // Saisir votre code ici\r\n    \r\n    return 0;\r\n  }\r\n\r\n}',
        refLanguage: {
          id: 1,
          language: "java",
        },
      },
    ],
    testCases: [
      {
        id: 1,
        title: "Test Case 1",
        input: "2:3",
        expectedOutput: "5",
        executionDuration: 3500,
        isDelete: false,
        codeId: 36,
        public: true,
      },
      {
        id: 2,
        title: "Test Case 2",
        input: "-3:-6",
        expectedOutput: "-9",
        executionDuration: 3500,
        isDelete: false,
        codeId: 36,
        public: true,
      },
      {
        id: 3,
        title: "Test Case 3",
        input: "7:3",
        expectedOutput: "10",
        executionDuration: 3500,
        isDelete: false,
        codeId: 36,
        public: true,
      },
    ],
  },
  {
    id: 2,
    title: "Question libre",
    isPublic: true,
    score: 10,
    content: "Ceci est une question libre, vous devez saisir votre réponse ",
    isDelete: null,
    questionType: "OPEN_ENDED",
    duration: 10,
    refLevelId: 1,
    refTechnologyId: 1,
    mode: null,
  },
  {
    id: 1,
    title: "QCM coche unique",
    isPublic: true,
    score: 15,
    content: "C'est une question QCM avec une seule réponse possible  ",
    isDelete: null,
    questionType: "QCM",
    duration: 10,
    refLevelId: 1,
    refTechnologyId: 1,
    mode: "strict",
    multipleAnswers: false,
    propositions: [
      {
        id: 1,
        proposition: "Réponse 1",
      },
      {
        id: 2,
        proposition: "Réponse 2",
      },
      {
        id: 3,
        proposition: "Réponse 3",
      },
      {
        id: 4,
        proposition: "Réponse 4",
      },
    ],
  },
  {
    id: 11,
    title: "QCM coche multiple",
    isPublic: true,
    score: 10,
    content:
      "C'est une question QCM avec une ou plusieurs réponses possibles  ",
    isDelete: null,
    questionType: "QCM",
    duration: 10,
    refLevelId: 1,
    refTechnologyId: 1,
    mode: "strict",
    multipleAnswers: true,
    propositions: [
      {
        id: 12,
        proposition: "Réponse 1",
      },
      {
        id: 13,
        proposition: "Réponse 2",
      },
      {
        id: 14,
        proposition: "Réponse 3",
      },
      {
        id: 15,
        proposition: "Réponse 4",
      },
    ],
  },
];

export const campaignInfo = {
  id: 2,
  title: "Une démo d'utilisation de la plateforme AutoHiring",
  description: "",
  status: "DRAFT",
  duration: 105,
  createdBy: "doe john",
  isDelete: false,
  refTechnologyLevelId: 1,
  totalQuestions: 4,
  type: campaignTypes.LIST_QUESTIONS,
};
