import React, { Fragment, useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { t } from "i18n";

export const FileUpload = (props) => {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (!isEmpty(files)) {
			props.upload(files[0]);
			props.toggle();
		}
	}, [files]); // eslint-disable-line

	const ondrop = (acceptedFiles) => {
		setFiles(acceptedFiles);
	};

	return (
		<Dropzone
			onDrop={(acceptedFiles) => {
				ondrop(acceptedFiles);
			}}
			maxSize={15000000}
			accept=".pdf"
		>
			{({ getRootProps, getInputProps, rejectedFiles }) => (
				<section className="Dropzone">
					<div {...getRootProps({ className: "Dropzone_container" })}>
						<input {...getInputProps()} accept=".pdf" />
						<div className="DropZone_center">
							<FontAwesomeIcon icon={faUpload} size="2x" />
							<p>{t("dropzone-message")}</p>
						</div>
					</div>

					{!isEmpty(rejectedFiles) ? (
						<Fragment>
							<aside className="m-3">{t("file-too-big")}</aside>
						</Fragment>
					) : (
						""
					)}
				</section>
			)}
		</Dropzone>
	);
};
