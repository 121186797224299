import React, { useState } from "react";
import { connect } from "react-redux";

import { reduxForm, Field } from "redux-form";
import {
	Row,
	Col,
	Label,
	Button,
	UncontrolledPopover,
	PopoverBody,
	Alert,
} from "reactstrap";

import {
	required,
	email,
	number,
	minValueInput,
	isValidName
} from "components/form/validationRules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { ValidatedInput } from "components/form/Input/ValidatedInput";
import { ValidatedSelected } from "components/form/Input/ValidatedSelected";
import { ValidatedInputCv } from "components/form/Input/ValidatedInputCv";
import { FileUpload } from "./dropZone";
import { titleOptions } from "components/constants/titleOptions";
import StateButton from "components/StateButton";
import { t } from "i18n";

const FormCandidat = (props) => {
	const [popoverOpen, setPopoverOpen] = useState(false);

	const toggle = () => {
		setPopoverOpen((popoverOpen) => !popoverOpen);
	};


	const { 
	   experienceOptions,
	   messageError,
	   isLoadingAddingCandidate,
		 moreSteps
	} = props;

	return (
		<form
			onSubmit={props.handleSubmit}
			className="pl-5 pr-5 ah-home-validation"
		>
			<Field
				className="mt-2"
				name="title"
				component={ValidatedSelected}
				label={t("Gender")}
				id="candidatTitle"
				validate={required}
				options={titleOptions}
			/>

			<Row form>
				<Col md={6}>
					<Field
						name="lastName"
						component={ValidatedInput}
						type="text"
						id="lastName"
						label={t("LastName")}
						validate={[required, minValueInput, isValidName]}
					/>
				</Col>

				<Col md={6}>
					<Field
						name="firstName"
						component={ValidatedInput}
						type="text"
						id="firstName"
						label={t("FirstName")}
						validate={[required, minValueInput, isValidName]}
					/>
				</Col>
			</Row>

			<Row form>
				<Col md={6}>
					<Field
						name="email"
						component={ValidatedInput}
						type="email"
						id="email"
						readOnly={true}
						label={t("E-mail")}
						validate={[required, email]}
					/>
				</Col>
				<Col md={6}>
					<Field
						name="phone"
						component={ValidatedInput}
						type="text"
						id="phone"
						label={t("Phone")}
						validate={[number, required]}
					/>
				</Col>
			</Row>

			<Field
				name="refExperienceId"
				component={ValidatedSelected}
				validate={required}
				options={experienceOptions}
				id="candidatExperience"
				label={t("Experience")}
			/>

			<Row className="mx-0 mb-4">
				<Col md={12} className="p-0">
					<Label className="ah-lbl-input">{t("Update_Your_CV")}</Label>
				</Col>
				<Col xs="11" className=" p-0">
					<Field
						name="cv"
						id="cv"
						component={ValidatedInputCv}
						type="text"
						disabled={true}
						inputValue={props.cvName}
					/>
				</Col>
				<Col xs="1" className=" p-0">
					<Button id="PopoverFocus" type="button" className="rounded-0">
						<FontAwesomeIcon icon={faPaperclip} size="1x" />
					</Button>
				</Col>
			</Row>
			{messageError && (
				<Row className="mx-0">
					<Col md={12} className="p-0">
						<Alert color="danger" xs="12">
							{messageError}
						</Alert>
					</Col>
				</Row>
			)}

			<div>
				<UncontrolledPopover
					trigger="legacy"
					placement="bottom"
					target="PopoverFocus"
					className="popover_cv"
					isOpen={popoverOpen}
					toggle={toggle}
				>
					<PopoverBody className="p-0">
						<div>
							<FileUpload upload={props.upload} toggle={toggle} />
						</div>
					</PopoverBody>
				</UncontrolledPopover>
			</div>
			<div className="text-right">
				<StateButton
					type="submit"
					color="primary"
					size="lg"
					onClick={() => props.change("cv", props.cvName)}
					isLoading={isLoadingAddingCandidate}
				>
					{moreSteps ? t("Next") : t("Submit")}
				</StateButton>
			</div>
		</form>
	);
};

const mapStateToProps = ({ candidat, campaignLink }, props) => {
	return {
		experienceOptions: candidat.experiencesOption.map(e => {
			return { label: e.label, value: e.id , id:e.id};
		  }),
		chooseTestTime:
			campaignLink.campaign.campaignLinkDTO &&
			campaignLink.campaign.campaignLinkDTO.chooseTestTime,
		initialValues: {
			title: props.candidat.title,
			lastName: props.candidat.lastName,
			firstName: props.candidat.firstName,
			email: props.candidat.email,
			phone: props.candidat.phone,
			refExperienceId: props.candidat.refExperienceId,
		},
	};
};
export default connect(mapStateToProps)(
	reduxForm({
		form: "addCandidat",
		enableReinitialize: true,
	})(FormCandidat)
);
