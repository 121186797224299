import React, { Component } from "react";
import logo from "../../assets/img/logo.png";
import { NavLink } from "react-router-dom";
import { ButtonG } from "../../components/form/Button";
import { t } from "../../i18n";

export default class page404 extends Component {
  render() {
    return (
      <div className="ah-error-page text-center">
        <NavLink to="/home">
          <img src={logo} className="ah-logo-error" alt={t("Novelis_logo")} />
        </NavLink>
        <h1 className="ah-errorpageh1"> {t("404_Error")} !</h1>
        <h5 className=" ah-errorpageh5 mb-5">
          {" "}
          {t("this_page_doesn_t_exist")} .
        </h5>
        <NavLink to="/">
          <ButtonG type="submit" color="primary" size="lg">
            {t("Go_to_home")}
          </ButtonG>
        </NavLink>
      </div>
    );
  }
}
