import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import logo from "../../assets/img/logo.png";
import {t} from "../../i18n";

export default class Footer extends Component {
  render() {
    return (
      <footer className="ah-footer">
        <Row>
          <Col xs="4">
            <img src={logo} className="ah-logo" alt="logo" />
          </Col>
          <Col xs="8" md="4" className="text-center pt-2">
          {t('Novelis_Consulting')} © {new Date().getFullYear()} {t('All_rights_reserved')} 
          </Col>
        </Row>
      </footer>
    );
  }
}
