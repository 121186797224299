import { GET_CURRENT_ROOT_ENTITY } from "./actionsTypes";
import { CURRENT_ROOT_ENTITY_URL } from "./endpoints";

export const getCurrentRootEntity = () => {
  return {
    type: GET_CURRENT_ROOT_ENTITY,
    request: {
      url: CURRENT_ROOT_ENTITY_URL
    }
  }
}