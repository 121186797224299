import React from "react";
import Select from "react-select";
import cs from "classnames";
import { FormFields } from "../FormFields";
import { t } from "i18n";

export const ValidatedSelected = (props) => {
	const {
		input,
		meta: { touched, error },
		className,
		id,
		onSelect,
		...rest
	} = props;

	const mvalue = rest.withValue
		? rest.options.find((i) => i.value === input.value)
		: rest.options.find((i) =>
				typeof input.value === "number"
					? i.id === input.value
					: i.value === input.value
		  );
	
	const NoOptionsMessage = ({text}) => {
			return (
			  <div>
				<span >{text}</span> 
			  </div>
			);
	 };

	return (
		<FormFields {...props}>
			<Select
				value={mvalue}
				placeholder={t("Select")}
				noOptionsMessage={() => <NoOptionsMessage text={t("No options")}/>}
				onChange={(e) => input.onChange(e)}
				className={cs("react-select","text-label", { "is-invalid": touched && error })}
				classNamePrefix="react-select"
				{...rest}
			/>
		</FormFields>
	);
};
