import { call, takeEvery, put, select } from "redux-saga/effects";
import { get } from "lodash";
import {
  POST_COMMENT_DATA,
  POST_COMMENT_SECCUES,
  POST_COMMENT_ERROR,
  POST_VIDEO_COMMENT_DATA,
} from "./actionsTypes";
import { postCommentApi, postVideoCommentApi } from "./api";

export function* postCommentData({ payload }) {
  try {
    const invitationId = yield select((state) =>
      get(state, "authentication.invitation.id")
    );
    let comment = payload.data.comment;
    const response = yield call(postCommentApi, {
      comment,
      invitationId,
      withVideo: false,
    });

    yield put({ type: POST_COMMENT_SECCUES, response });

    if (payload.success) {
      payload.success();
    }
  } catch (error) {
    yield put({ type: POST_COMMENT_ERROR, error });
  }
}

export function* postVideoCommentData({ payload }) {
  let withVideo = true;
  try {
    const invitationId = yield select((state) =>
      get(state, "authentication.invitation.id")
    );
    let formData = payload.data;
    let { config } = payload;
    formData.append("fileId", invitationId);

    const response = yield call(postVideoCommentApi, {
      formData,
      config,
    });

    if ([200].includes(response.status)) {
      let comment = response.data;
      const res = yield call(postCommentApi, {
        comment,
        invitationId,
        withVideo,
      });
      if ([201].includes(res.status)) {
        payload.success();
      }
    }
  } catch (error) {
    yield put({ type: POST_COMMENT_ERROR, error });
  }
}

export default function* actionWatcherComment() {
  yield takeEvery(POST_COMMENT_DATA, postCommentData);
  yield takeEvery(POST_VIDEO_COMMENT_DATA, postVideoCommentData);
}
