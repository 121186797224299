import instance from "../../shared/utils/instance";

instance.defaults.timeout = 600000;

export const postCommentApi = (body, campaignId, candidateId) =>
  instance.post(`/confirmationCandidate`, body, campaignId, candidateId);

export const postVideoCommentApi = (data) => {
  // return instance.post(`/upload-video`, data.formData);
  return instance.request({
    method: "post",
    url: `/upload-video`,
    data: data.formData,
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      data.config(percentCompleted);
    },
  });
};
