import React, { Fragment, useState } from "react";
import { getQuerySelector } from '@redux-requests/core';
import { useDispatch, useSelector } from "react-redux";

import { isEmpty, isNull } from "lodash";

import { Row, Col, CardHeader, CardBody, Progress } from "reactstrap";
import { ButtonG } from "../../components/form/Button";
import { CONTACT_US, server_uri_api } from "../../shared/config/constants";
import { postComment, postVideoComment } from "../../redux/comment/action";
import { ValidationForm } from "./validationForm";
import { t } from "../../i18n";
import { Loader } from "../../components/Loader";
import VideoRecorder from "react-video-recorder";

import { GET_CURRENT_ROOT_ENTITY } from "redux/rootEntity/actionsTypes";
import { DOWNLOAD_LOGO_ENTITY } from "redux/rootEntity/endpoints";

const ValidationPage = (props) => {
	const [isloading, setIsloading] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [percentCompleted, setPercentCompleted] = useState(0);
	const [videoBlob, setVideoBlob] = useState();
	const [showSubmit, setShowSubmit] = useState();

	const dispatch = useDispatch();

	const submitComment = (values) => {
		setIsloading(true);
		dispatch(
			postComment(values, () => {
				setIsloading(false);
				props.history.push(`/Success`);
			})
		);
	};

	const submitVideoComment = () => {
		const file = videoBlob;
		setIsloading(true);
		setIsUploading(true);

		const success = () => {
			setIsloading(false);
			props.history.push(`/Success`);
		};

		const formData = new FormData();
		formData.append("file", file);

		const updatePercentCompleted = (percentCompleted) => {
			setPercentCompleted(percentCompleted);
		};

		dispatch(postVideoComment(formData, success, updatePercentCompleted));
	};

	const { emailRecuiter } = useSelector(
		({ authentication }) => authentication.invitation
	);

	const campaignInfo = useSelector(
		({ authentication }) => authentication.campaign
	);

	const currentRootEntity = useSelector(
		getQuerySelector({ type: GET_CURRENT_ROOT_ENTITY })
	).data;

	return (
		<Fragment>
			<CardHeader className="ah-cardheader-valid pb-0">
				<Row>
					<Col xs="12" sm="6">
						<p className="ah-p2-standard m-0 ah-compagne-title">
							{campaignInfo?.title}
						</p>
					</Col>
					<Col xs="6" className="text-right d-none d-sm-block">
						{isEmpty(currentRootEntity) ||
						 isNull(currentRootEntity.logoEntity) ?
							"":
							<img
									src={`${server_uri_api}${DOWNLOAD_LOGO_ENTITY}/${currentRootEntity.name}/${currentRootEntity.logoEntity}`}
									alt="logo"
									className="ah-question-logo"
							/>
						}
					</Col>
				</Row>
			</CardHeader>
			<CardBody className="ah-cardbody text-center">
				<div className="px-4 my-scrollbar">
					<div className="pt-2">
						<h5 className="ah-validh5">
							{t("Thank_you_for_taking_this")}. <br />
							{t("your_ansewers_were_well_ent")}.
						</h5>
						<h6 className="ah-validh6 mt-4">
							{t("Feel_free_to_leave_us_your_comments_and_suggestions")}
						</h6>
					</div>
					{campaignInfo.withVideo ? (
						<div
							className="d-flex  align-items-center"
							style={{ height: "80%" }}
						>
							{isUploading ? (
								<Progress bar animated value={percentCompleted}>
									{percentCompleted} %
								</Progress>
							) : (
								<VideoRecorder
									onRecordingComplete={(videoBlob) => {
										setVideoBlob(videoBlob);
										setShowSubmit(true);
									}}
									timeLimit={30000}
									showReplayControls
									replayVideoAutoplayAndLoopOff
									isReplayVideoInitiallyMuted={false}
									mimeType='video/webm;codecs="vp8,opus"'
								/>
							)}
						</div>
					) : (
						<ValidationForm onSubmit={submitComment} isloading={isloading} />
					)}
					{isloading && !isUploading && <Loader />}
					</div>
				{campaignInfo.withVideo && (
					<div>
						{" "}
						{showSubmit ? (
							<ButtonG
								type="submit"
								color="primary"
								size="lg"
								className="mt-3"
								onClick={submitVideoComment}
							>
								Upload{" "}
							</ButtonG>
						) : (
							<ButtonG
								type="submit"
								color="primary"
								size="lg"
								className="mt-3"
								onClick={submitComment}
							>
								Confirmer sans video{" "}
							</ButtonG>
						)}
					</div>
				)}
				<i>
					<h4 className=" ah-success-text2">
						{t("You_have_questions_dont_hesitate_to_contact_us_on")}
						{" : "}
						{emailRecuiter || CONTACT_US}
					</h4>
				</i>
			</CardBody>
		</Fragment>
	);
};
export default ValidationPage;
