export const invitationStatus = Object.freeze({
	NOT_STARTED: "NOT_STARTED", 
	INPROGRESS: "INPROGRESS",
	FINISHED: "FINISHED",
	EXPIRED: "EXPIRED"
});

export const campaignTypes = Object.freeze({
	LIST_QUESTIONS: "LIST_QUESTIONS",
	RANDOM_QUESTIONS: "RANDOM",
});
