import { all } from "redux-saga/effects";
import instructionSaga from "./instructions/saga";
import campaignInfoSaga from "./campaignInfo/saga";
import questionSaga from "./questions/saga";
import authenticationSaga from "./authentication/saga";
import actionWatcherCandidate from "./candidat/saga";
import actionWatcherComment from "./comment/saga";
import actionWatcherCampaignLink from "./campaignLink/saga";

export default function* rootSaga() {
	yield all([
		instructionSaga(),
		actionWatcherCandidate(),
		actionWatcherComment(),
		campaignInfoSaga(),
		questionSaga(),
		authenticationSaga(),
		actionWatcherCampaignLink(),
	]);
}
