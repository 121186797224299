import { t } from "../../../i18n";
import XRegExp from "xregexp";

export const required = value => (value ? undefined : t("Required"));
export const tel = value =>
  value.length < 10 ? t("Invalid_number_phone") : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? t("Invalid_email_address")
    : undefined;
export const number = value =>
  value && isNaN(Number(value)) ? t("Invalid_number_phone") : undefined;
export const maxLength = max => value =>
  value && value.length > max
    ? t("Must_be") + " " + max + " " + t("characters_or_less")
    : undefined;
export const maxLengthTel = maxLength(10);
export const minValue = min => value =>
  value && value.length < min
    ? t("Must_be_at_least") + " " + min + " " + t("characters")
    : undefined;
export const minValueInput = minValue(3);

export const isValidName = value => {
  //that matches any kind of letter from any language
  return !XRegExp("^[\\p{L} .'-]*$").test(value)
    ? t("error.invalide.format")
    : undefined;
};
