export const GET_QUESTIONS_DATA = "GET_QUESTIONS_DATA";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_RANDOM_QUESTION = "GET_RANDOM_QUESTION";
export const GET_RANDOM_QUESTION_SUCCESS = "GET_RANDOM_QUESTION_SUCCESS";
export const POST_RUN_CODE = "POST_RUN_CODE";
export const SET_USER_RESPONSE = "SET_USER_RESPONSE";
export const GET_TEST_CASE_RESULTS = "GET_TEST_CASE_RESULTS";
export const EXECUTE_TEST_CASE = "EXECUTE_TEST_CASE";
export const GET_ALL_QUESTIONS = "GET_ALL_QUESTIONS";
export const SUBMIT_TEST = "SUBMIT_TEST";
export const SET_ANSWERS = "SET_ANSWERS";
export const SAVE_QUESTION_ANSWER = "SAVE_QUESTION_ANSWER";

