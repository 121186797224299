import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../assets/img/logo.png";
import { t } from "../../i18n";
import CustomCountdown from "../../components/CustomCountDown";

class WaitCmp extends Component {
  render() {
    const { candidate, campaignInfo, restTimeToStart } = this.props;

    return (
      <div className="ah-container">
        <img
          src={logo}
          alt={t("Novelis_logo")}
          className="ah-nvlogo img-fluid mx-auto"
        />
        <h3>{campaignInfo?.title}</h3>
        <p className="ah-paragraph">
          {t("Hello")}{" "}
          <strong>
            {candidate?.lastName.toUpperCase() +
              " " +
              candidate?.firstName.toUpperCase()}
          </strong>
          <br />
          {t("You_have_been_invited_to_attend_the_challenge")}{" "}
          {campaignInfo?.title}
          <br />
          {t("Your_challenge_will_be_starting_in")}
        </p>
        <CustomCountdown
          date={restTimeToStart}
          onComplete={() => {
            window.location.href = window.location.href.split("#/")[0];
          }}
          fullTime={true}
        />

        <p>{t("We_wish_you_all_the_best")}!</p>
      </div>
    );
  }
}

const mapStateToPros = ({ authentication }) => {
  return {
    candidate: authentication.candidate,
    campaignInfo: authentication.campaign,
    invitation: authentication.invitation,
    restTimeToStart: authentication.restTimeToStart
  };
};

export const Wait = connect(mapStateToPros)(WaitCmp);
