import React, { Component } from "react";
import Checkbox from "../../form/Checkbox";
import RadioButton from "../../form/RadioButton";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { setCandidatResponse } from "../../../redux/questions/action";
import QuestionContent from "components/QuestionContent";
class TestQCMCmp extends Component {

  onChange = (id) => {
    let { candidatResponse = {} } = this.props;
    let response = candidatResponse.propositions || [];
    response = response.map((i) => i.id);

    if (response.includes(id)) {
      response = response.filter((item) => item !== id);
    } else response.push(id);

    this.props.setCandidatResponse({
      propositions: response.map((id) => ({ id })),
      isChanged: true,
    });
  };

  createCheckboxes = (propositions) => {
    const checkedPropositions = this.props.candidatResponse?.propositions ? 
    this.props.candidatResponse.propositions.map(item => item.id) : 
      null;
    return propositions.map((item, key) => (
      <Checkbox
        key={key}
        label={item.proposition}
        value={item.id}
        handleCheckboxChange={this.onChange}
        className="ah-checkbox"
        isChecked={checkedPropositions?.includes(item.id) ? true : false}
      />
    ));
  }
    
  createRadiobuttons = (propositions) => {
    const checkedProposition = this.props.candidatResponse?.propositions ? 
      this.props.candidatResponse.propositions[0] : 
      null;
      
    return propositions.map((item, key) => (
      <RadioButton
        name="radioQCM"
        label={item.proposition}
        key={key}
        value={item.id}
        defaultChecked = {item.id === parseInt(checkedProposition?.id) ? true : false}
        onChange={(e) => {
            this.props.setCandidatResponse({
              propositions: [{ id: e.target.value }],
              isChanged: true
            })
          }
        }
      />
    ));
  };
  render() {
    const { content, propositions, multipleAnswers } = isEmpty(
      this.props.question
    )
      ? this.props.mock
      : this.props.question;

    return (
      <div className="ah-50 overflow-auto ah-content">
          <QuestionContent content={content}/>
          <div className="ah-container-qcm  ">
            {!multipleAnswers
              ? this.createRadiobuttons(propositions)
              : this.createCheckboxes(propositions)}
          </div>
      </div>
    );
  }
}

const mapStateToPros = ({ questions }) => {
  return {
    candidatResponse: questions.candidatResponse,
    multipleAnswer: questions.current.multipleAnswers,
  };
};

export const TestQCM = connect(mapStateToPros, { setCandidatResponse })(
  TestQCMCmp
);
