import React from "react";
import { Select } from "../Select";

const RenderSelectInput = ({ input, options, name, id }) => (
  <div>
    <Select
      {...input}
      name={name}
      options={options}
      id={id}
      value={options.find((option) => option.value === input.value)}
      onChange={({ value }) => input.onChange(value)}
      onBlur={() => input.onBlur()}
    />
  </div>
);

export default RenderSelectInput;
