import React from "react";
import { Row, Col } from "reactstrap";
import { getQuery } from '@redux-requests/core';
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
 
import CustomCountdown from "components/CustomCountDown";

import { server_uri_api } from "shared/config/constants";
import { GET_CURRENT_ROOT_ENTITY } from "redux/rootEntity/actionsTypes";
import { DOWNLOAD_LOGO_ENTITY } from "redux/rootEntity/endpoints";

import { t } from "i18n";

const CompagneInfo = (props) => {
	const { 
		campaign,
		onComplete,
		campaignLink,
		currentRootEntity
	} = props;
 
	return ( 
		<Row>
			<Col md={12} className="text-center">
				{isEmpty(currentRootEntity) ||
				 isNull(currentRootEntity.logoEntity) ?
				   "":
					 <img
							src={`${server_uri_api}${DOWNLOAD_LOGO_ENTITY}/${currentRootEntity.name}/${currentRootEntity.logoEntity}`}
							alt="Compagne logo"
							className="w-50"
				   />
				 }
				<div className="h3 mt-4">{campaign && campaign.title}</div>

				<div className="h6 mt-4">{t("The_Registration_expire_in")}</div>
				{campaignLink.restTimeToEnd ? (
					<CustomCountdown
						date={campaignLink.restTimeToEnd}
						onComplete={onComplete}
						fullTime={false}
						dangerTime={60}
					/>
				) : null}
			</Col>
		</Row>
	);
};

const mapStateToPros = ({ campaignLink, ...state }) => {
	return {
		campaign: campaignLink.campaign.campaignDTO,
		LinkRegistration: campaignLink.campaign.campaignLinkDTO,
		campaignLink: campaignLink.campaign,
		currentRootEntity: getQuery(state, {
			type: GET_CURRENT_ROOT_ENTITY,
		}).data,							
	};
};

export default connect(mapStateToPros)(CompagneInfo);
