import React, { useEffect, useRef } from 'react';

const QuestionContent = ({ content, isCode = false }) => {
  const pRef = useRef();
  useEffect(() => {
    const pTag = pRef.current;
    if (!pTag) return;
    pTag.addEventListener('contextmenu', preventDefault);
    pTag.addEventListener('keydown', preventCopyPasteShortcut);
    pTag.addEventListener('copy', preventDefault);
    pTag.addEventListener('paste', preventDefault);
    pTag.addEventListener('cut', preventDefault);
    pTag.style.webkitUserSelect = 'none';
    pTag.style.msUserSelect = 'none';
    pTag.style.userSelect = 'none';

    return () => {
      pTag.removeEventListener('contextmenu', preventDefault);
      pTag.removeEventListener('copy', preventDefault);
      pTag.removeEventListener('paste', preventDefault);
      pTag.removeEventListener('cut', preventDefault);
      pTag.removeEventListener('keydown', preventCopyPasteShortcut);
    }
  }, [])

  return (
    <p
      ref={pRef}
      className={`ah-question-content ${!isCode ? 'ah-max-height' : ''}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

const preventDefault = (event) => {
  event.preventDefault();
};

const preventCopyPasteShortcut = (event) => {
  if ((event.ctrlKey || event.metaKey) && ['x', 'X', 'c', 'C', 'v', 'V'].includes(event.key)) {
    event.preventDefault();
  }
};
export default QuestionContent;
