import React from "react";
import { Row, Col, CardFooter } from "reactstrap";
import { ButtonG } from "../../components/form/Button";
import { CountDown } from "../../components/CountDown";
import { campaignTypes } from "../../shared/constants/invitationStatus";
import { t } from "../../i18n";

const QuestionFooter = ({
  campaignInfo,
  restTimeToEndCampaign,
  questionDuration,
  loading,
  current,
  onNextQuestion,
  onPrevQuestion,
  onDurationExpired,
}) => {
  let startTime;
  if (!loading) {
    startTime = Date.now();
  }
  const isListQuestions = campaignInfo.type === campaignTypes.LIST_QUESTIONS;
  return (
    <CardFooter className="ah-cardfooter pb-4">
      <Row>
        {isListQuestions ? (
          <Col xs="4" className="text-left">
            {current !== 1 && (
              <ButtonG
                type="submit"
                color="primary"
                size="lg"
                onClick={() =>
                  onPrevQuestion(Math.floor((Date.now() - startTime) / 1000))
                }
                className="mt-0 "
              >
                {t("Previous")}
              </ButtonG>
            )}
          </Col>
        ) : null}

        <Col
          xs={isListQuestions ? "4" : "6"}
          className={isListQuestions ? "text-center" : "text-left"}
        >
          {!loading && (
            <CountDown
              duration={
                isListQuestions ? restTimeToEndCampaign : questionDuration
              }
              onComplete={isListQuestions ? onDurationExpired : onNextQuestion}
            />
          )}
        </Col>
        <Col xs={isListQuestions ? "4" : "6"} className="text-right">
          <ButtonG
            type="submit"
            color="primary"
            size="lg"
            onClick={() =>
              onNextQuestion(Math.floor((Date.now() - startTime) / 1000))
            }
            className="mt-0 ah-button-next"
          >
            {current === campaignInfo.totalQuestions? t("Finish") : t("Next")}
          </ButtonG>
        </Col>
      </Row>
    </CardFooter>
  );
};

export default QuestionFooter;
