import {
  PUT_CANDIDAT_SUCCESS,
  GET_CANDIDATE_SUCCESS,
  PUT_CANDIDAT_DATA,
  GET_CANDIDATE_DATA,
  PUT_CANDIDAT_ERROR,
  GET_CANDIDATE_ERROR,
  GET_EXPERIENCE_SUCCESS,
  GET_EXPERIENCE_DATA,
  GET_EXPERIENCE_ERROR,
	POST_CV,
  GRADE_CANDIDATE
} from "./actionsTypes";

const initialState = {
  data: [],
  candidat: {},
  loading: false,
  experiencesOption: [],
};

// eslint-disable-next-line
export default (state = initialState, payload) => {
  switch (payload.type) {
    case PUT_CANDIDAT_DATA:
      return { ...state, loading: true };
    case PUT_CANDIDAT_SUCCESS:
      return { ...state, data: payload.candidatForm, loading: false };
    case PUT_CANDIDAT_ERROR:
      return { ...state, errorMessage: payload.error, loading: false };
    case GET_CANDIDATE_DATA:
      return { ...state, loading: true };
    case GET_CANDIDATE_SUCCESS:
      return { ...state, candidat: payload.data, loading: false };
    case GET_CANDIDATE_ERROR:
      return { ...state, errorMessage: payload.error, loading: false };
    case GET_EXPERIENCE_DATA:
      return { ...state, loading: true };
    case GET_EXPERIENCE_SUCCESS:
      return { ...state, experiencesOption: payload.data, loading: false };
    case GET_EXPERIENCE_ERROR:
      return { ...state, errorMessage: payload.error, loading: false };
		case POST_CV:
			return { ...state, errorMessage: payload.error };
    case GRADE_CANDIDATE:
        return {...state };
    default:
      return state;
  }
};
