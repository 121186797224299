import {
  REQUEST_AUTHENTICATION,
  RECEIVE_AUTHENTICATION_DATA,
  SET_MODE,
  GET_RECRUITER_LOGO,
  SET_RECRUITER_LOGO,
  GET_RECRUITER_LOGO_ERROR
} from "./actionsType";

export const requestAuthentication = token => ({
  type: REQUEST_AUTHENTICATION,
  token
});

export const receiveAuthenticationData = authData => ({
  type: RECEIVE_AUTHENTICATION_DATA,
  authData
});

export const setMode = mode => ({
  type: SET_MODE,
  payload: mode
});

export const getLogoRecruiter = data => ({
  type: GET_RECRUITER_LOGO,
  payload: data
});

export const setLogoRecruiter = data => ({
  type: SET_RECRUITER_LOGO,
  payload: data
});

export const getLogoRecruiterError = data => ({
  type: GET_RECRUITER_LOGO_ERROR,
  payload: data
});
