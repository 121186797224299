import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import CompagneInfo from "../HomeConfirmation/CompagneInfo";
import Instructions from "../../components/Instructions";
import logo from "../../assets/img/logo.png";

export default class instruction extends Component {
  render() {
    return (
      <div className="container-fluid h-100">
        <Row className="mt-3">
          <Col xs="12" sm={6} className="border-right">
            <CompagneInfo />
          </Col>
          <Col xs="12" sm={6}>
            <Instructions />
          </Col>
          <Col xs="6">
            <div className="devStyle" />
          </Col>
          <Col xs="6" className="text-right">
            <img src={logo} className="col-6" alt="logo" />
          </Col>
        </Row>
      </div>
    );
  }
}
