import { connect } from "react-redux";
import { getMutation, getQuery } from "@redux-requests/core";
import { get, isInteger } from "lodash";
import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { Modal, ModalBody, ModalFooter, Button} from "reactstrap";
import { t } from "../../i18n";
import QuestionFooter from "../../components/QuestionFooter";

import { Question } from "../../components/Question";
import { TestQCM } from "../../components/Question/QCM/TestQCM";
import { TestLibre } from "../../components/Question/libre";
import { TestCoding } from "../../components/Question/Codage/TestCoding";
import {
  getQuestion,
  getAllQuestions,
  saveQuestion,
  setCandidatResponse,
  submitTest
} from "../../redux/actions";

import { GET_CURRENT_ROOT_ENTITY } from "redux/rootEntity/actionsTypes";
import {
  GET_ALL_QUESTIONS,
  SAVE_QUESTION_ANSWER,
  SUBMIT_TEST
} from "redux/questions/actionsTypes";
import { campaignTypes } from "../../shared/constants/invitationStatus";

class QuestionPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalRunCode: false,
      modalFinishConfirm: false,
    };
  }

  componentDidMount() {
    if (this.props.campaignInfo.type === campaignTypes.LIST_QUESTIONS) {
      this.props.getAllQuestions(
        this.props.invitation.id,
        this.getIndexParam()
      );
    } else {
      if (this.props.campaignInfo.totalQuestions > this.props.counter) {
        this.props.getQuestion();
      } else {
        this.props.history.push("/Validation");
      }
    }
  }

  renderQuestion = (question) => {
    const isOrderedCampaign =
      this.props.campaignInfo.type === campaignTypes.LIST_QUESTIONS;
    const counter = isOrderedCampaign
      ? this.getIndexParam()
      : this.props.counter;
    switch (get(question, "questionType.name")) {
      case "QCM":
        return <TestQCM question={question} counter={counter} />;
      case "OPEN_ENDED":
        return <TestLibre question={question} counter={counter} />;
      case "CODE":
        return (
          <TestCoding
            toggleCodeModal={this.toggleCodeModal}
            modalRunCode={this.state.modalRunCode}
            question={question}
            counter={counter}
            language={this.props.candidatResponse?.language}
          />
        );
      default:
        return "";
    }
  };

  getIndexParam = () => {
    const parsedParam = parseInt(this.props.match.params.questionIndex);
    return parsedParam > 0 &&
      parsedParam <= this.props.campaignInfo.totalQuestions
      ? parsedParam
      : 1;
  };

  onSaveQuestion = (time, isNext, index, durationExpired) => {
    const isOrderedCampaign =
      this.props.campaignInfo.type === campaignTypes.LIST_QUESTIONS;
    const counter = isOrderedCampaign
      ? this.getIndexParam()
      : this.props.counter;
    const question = isOrderedCampaign
      ? this.props.questions.data[counter - 1]?.question
      : this.props.question;
    let duration = isInteger(time) ? time : question.duration;
    if (isOrderedCampaign) duration = undefined;

    if (this.props.candidatResponse?.isChanged === true || !isOrderedCampaign) {
      const answer = {
        questionId: question?.id,
        invitationId: this.props.invitation.id,
        duration,
        id: this.props.answers[counter - 1]?.id,
        ...this.props.candidatResponse,
      };

      const invitationInfo = {
        totalQuestions: this.props.campaignInfo?.totalQuestions,
        isOrderedCampaign,
        invitationId: this.props.invitation.id,
      };

      this.props.saveQuestion(
        answer,
        question.questionType.name,
        counter,
        invitationInfo,
        isNext,
        index,
        durationExpired,
        this.toggleModalFinishConfirm,
        this.saveSuccess
      );
    } else {
      this.saveSuccess(isNext, index, true, durationExpired);
    }
  };

  saveSuccess = (isNext, index, isNotChanged, durationExpired) => {
    const isOrderedCampaign =
      this.props.campaignInfo.type === campaignTypes.LIST_QUESTIONS;
    const counter = isOrderedCampaign
      ? this.getIndexParam()
      : this.props.counter;
    const totalQuestions = this.props.campaignInfo.totalQuestions;

    if (counter === totalQuestions && isNext) {
      if(isNotChanged) {
        if(isOrderedCampaign && !durationExpired) {
          this.toggleModalFinishConfirm();
        } else this.props.submitTest(this.props.invitation.id);
      }
      return;
    }

    if (isOrderedCampaign) {
      let newCounter = index;
      if (isNext) {
          newCounter = counter + 1;
      } else if (index === undefined && counter > 1) {
          newCounter = counter - 1;
      }
      if(!durationExpired) this.props.history.push("/test/" + newCounter);
      if(isNotChanged) this.props.setCandidatResponse(this.props.answers[newCounter-1]);
    }
  };

  onNextQuestion = (time) => {
    this.closeCodeModal(() => this.onSaveQuestion(time, true));
  };

  onPrevQuestion = (time) => {
    this.closeCodeModal(() => this.onSaveQuestion(time, false));
  };

  onSelectQuestion = (index, time) => {
    this.closeCodeModal(() => this.onSaveQuestion(time, false, index+1));
  }

  onDurationExpired = () => {
    this.onSaveQuestion(0, true, undefined, true);
    if(this.getIndexParam() !==  this.props.campaignInfo.totalQuestions) this.props.submitTest(this.props.invitation.id);
  };

  toggleCodeModal = () => {
    this.setState(
      (prevState) => ({
        modalRunCode: !prevState.modalRunCode,
      }));
  };

  closeCodeModal = (callback) => {
    this.setState({ modalRunCode: false }, callback);
  };

  toggleModalFinishConfirm = () => {
    this.setState(({modalFinishConfirm}) => {
      return {modalFinishConfirm: !modalFinishConfirm}
    });
  }
  
  confirmFinishTest = () => {
    this.props.submitTest(this.props.invitation.id)
  }

  render() {
    const {
      currentRootEntity,
      campaignInfo = {},
      loading,
      restTimeToEndCampaign,
    } = this.props;

    const isOrderedCampaign =
      this.props.campaignInfo.type === campaignTypes.LIST_QUESTIONS;
    const counter = isOrderedCampaign
      ? this.getIndexParam()
      : this.props.counter;

    let question;
    if (isOrderedCampaign) {
      question = this.props.questions.data
        ? this.props.questions.data[counter - 1]?.question
        : {};
    } else question = this.props.question;

    return (
      <>
        <Question
          total={campaignInfo.totalQuestions || 10}
          current={counter}
          content={campaignInfo?.title}
          isLoading={
            isOrderedCampaign
              ? this.props.questions.loading || this.props.isSaving || this.props.isSubmitting
              : loading || this.props.isSaving
          }
          campaignType={campaignInfo.type}
          currentRootEntity={currentRootEntity}
          questions={this.props.questions.data?.map(iq => iq.question)}
          onSelectQuestion={this.onSelectQuestion}
        >
          {this.renderQuestion(question)}
        </Question>
        <QuestionFooter
          campaignInfo={campaignInfo}
          restTimeToEndCampaign={restTimeToEndCampaign}
          questionDuration={
            isOrderedCampaign
              ? this.props.campaignInfo.duration
              : question?.duration
          }
          loading={isOrderedCampaign ? this.props.questions.loading : loading || this.props.isSaving}
          onNextQuestion={this.onNextQuestion}
          onComplete={this.onNextQuestion}
          onPrevQuestion={this.onPrevQuestion}
          onDurationExpired={this.onDurationExpired}
          current={counter}
        />

        <Modal 
          isOpen={this.state.modalFinishConfirm} 
          toggle={this.toggleModalFinishConfirm}
          centered
        >
          <ModalBody>{t("warnFinishTest")}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalFinishConfirm}>
              {t("Cancel")}
            </Button>
            <Button color="danger" onClick={this.confirmFinishTest}>
              {t("Confirm")}
            </Button>{" "}
          </ModalFooter>
        </Modal>

      </>
    );
  }
}
const mapStateToPros = ({ questions, authentication, ...state }) => {
  return {
    campaignInfo: authentication.campaign,
    question: questions.current,
    candidatResponse: questions.candidatResponse,
    counter: questions.counter,
    loading: questions.loading,
    id: questions.id,
    answers: questions.answers,
    invitation: authentication.invitation,
    restTimeToEndCampaign: authentication.restTimeToEndCampaign,
    currentRootEntity: getQuery(state, {
      type: GET_CURRENT_ROOT_ENTITY,
    }).data,
    questions: getQuery(state, {
      type: GET_ALL_QUESTIONS,
    }),
    isSaving: getMutation(state, {
      type: SAVE_QUESTION_ANSWER,
    }).loading,
    isSubmitting: getQuery(state, {
      type: SUBMIT_TEST
    }).loading,
  };
};
export const QuestionPage = withRouter(
  connect(mapStateToPros, {
    getQuestion,
    getAllQuestions,
    saveQuestion,
    setCandidatResponse,
    submitTest
  })(QuestionPageComponent)
);
