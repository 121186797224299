import {
	GET_CAMPAIGN_LINK_DATA,
	GET_CAMPAIGN_LINK_SUCCESS,
	GET_CAMPAIGN_LINK_ERROR,
} from "./actionsTypes";

export const getCampaignLink = (link) => ({
	type: GET_CAMPAIGN_LINK_DATA,
	link,
});

export const getCampaignLinkDone = (data) => ({
	type: GET_CAMPAIGN_LINK_SUCCESS,
	data,
	loading: false,
});
export const getCampaignLinkError = (data) => ({
	type: GET_CAMPAIGN_LINK_ERROR,
	data,
	loading: false,
});
