import { FormFields } from "../form/FormFields";
import cs from "classnames";
import TimezoneSelect from 'react-timezone-select'
import { t } from "i18n";
 
const TimeZone = props => {
    const {
      input,
      className,
      inputClassName,
      meta: { touched, error },
      placeholder,
      ...rest
    } = props;
    return (
        <FormFields {...props}>
            <TimezoneSelect        
                className={cs("react-select",inputClassName, {
                "is-invalid": touched && error
                })}
                classNamePrefix="react-select"
                value={input.value}
                onChange={input.onChange}
                placeholder={placeholder ? t(placeholder) : "Select"}
                {...rest}
            />
            
            
        </FormFields>

    );




};


export default TimeZone;
 