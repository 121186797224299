import React from "react";
import PropTypes from "prop-types";
import { Textarea } from "../../components/form/TextArea";
import { ButtonG } from "../../components/form/Button";
import { t } from "../../i18n";
import { reduxForm, Field } from "redux-form";

const validationFormCmp = ({ handleSubmit, isloading }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        rows="8"
        component={Textarea}
        name="comment"
        id="comment"
        placeholder={t("Comment")}
        countShow={false}
        disabled={isloading}
      />
      <ButtonG
        type="submit"
        color="primary"
        size="lg"
        className="mt-3"
        disabled={isloading}
      >
        {t("Send")}
      </ButtonG>
    </form>
  );
};

validationFormCmp.defaultProps = {
  onSubmit: undefined
};

validationFormCmp.propTypes = {
  onSubmit: PropTypes.func
};

export const ValidationForm = reduxForm({
  form: "comment"
})(validationFormCmp);
