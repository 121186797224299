import React from "react";
import { Route, Switch } from "react-router-dom";
import HomeConfirmation from "./HomeConfirmation";
import { parse } from "query-string";
import validationPage from "./validation";
import page404 from "./page404";
import successPage from "./successPage";
import { QuestionPage } from "./questionPage";
import instructionPage from "./instructionPage";
import { Expired } from "./sessionExpired";
import { Wait } from "./waitingTestPage";
import { BadToken } from "./badToken";
import CampaignMock from "./tutorial";
import CandidateRegistration from "./candidateRegistration";

const PrivateRoute = ({ checkRoutes, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkRoutes() ? checkRoutes() : <Component {...props} />
    }
  />
);

export const Routes = ({ checkRoutes }) => {
	return (
		<div className="view-routes h-100">
			<Switch>
				<PrivateRoute
					checkRoutes={checkRoutes}
					exact
					path="/"
					component={
						parse(window.location.search).reg
							? CandidateRegistration
							: HomeConfirmation
					}
				/>
				<PrivateRoute
					checkRoutes={checkRoutes}
					path="/inscription"
					exact
					component={CandidateRegistration}
				/>
				<PrivateRoute
					checkRoutes={checkRoutes}
					path="/Success"
					exact
					component={successPage}
				/>

				<PrivateRoute
					checkRoutes={checkRoutes}
					path="/instruction"
					exact
					component={instructionPage}
				/>
				<PrivateRoute
					checkRoutes={checkRoutes}
					path="/test/:questionIndex?"
					exact
					component={QuestionPage}
				/>
				<PrivateRoute
					checkRoutes={checkRoutes}
					path="/Validation"
					exact
					component={validationPage}
				/>

        <PrivateRoute
          checkRoutes={checkRoutes}
          path="/expired"
          exact
          component={Expired}
        />
        <PrivateRoute
          checkRoutes={checkRoutes}
          path="/startingsoon"
          exact
          component={Wait}
        />
        <PrivateRoute
          checkRoutes={checkRoutes}
          path="/badtoken"
          exact
          component={BadToken}
        />
        <Route path="/tutorial" component={CampaignMock} />
        <PrivateRoute checkRoutes={checkRoutes} component={page404} />
      </Switch>
    </div>
  );
};
