import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import { HashRouter as Router } from "react-router-dom";
import { parse } from "query-string";
import { Redirect } from "react-router-dom";
import { Routes } from "../../routes/routes";
import { I18nextProvider } from "react-i18next";
import { i18n } from "../../i18n";
import classnames from "classnames";
import { requestAuthentication, setMode, getCampaignLink } from "redux/actions";

import { Loader } from "../../components/Loader";
import { invitationStatus } from "../../shared/constants/invitationStatus"

const smallCardPaths = ["/startingsoon", "/expired", "/badtoken"];

const parsed = parse(window.location.search);

class ContainerCmp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: null,
		};
	}
	componentDidMount() {
		if ("reg" in parsed) {
			this.props.getCampaignLink(parsed.reg);
		} else {
			this.props.requestAuthentication(parsed.q);
			const parsedUrl = new URL(window.location.href);
			const mode = parsedUrl.searchParams.get("mode");
			this.props.setMode(mode);
		}
	}
	checkRoutes = () => {
		const {
			invitation = {},
			campaign = {},
			counter,
			restTimeToStart,
			restTimeToEnd,
		} = this.props;
		const expirationDate =
			counter > 0
				? restTimeToEnd + campaign.duration * 60000 + 300000 // convert duration to millisec + 5 min
				: restTimeToEnd;

		const isExpired = expirationDate < 0;

		const isStarted = restTimeToStart < 0;

		const path =
			window.location.href.split("#/").length > 1
				? window.location.href.split("#/")[1]
				: "";
		if (invitation.error) {
			return path.startsWith("badtoken") ? "" : <Redirect to="/badtoken" />;
		}
		if (isExpired) {
			return path.startsWith("expired") ? "" : <Redirect to="/expired" />;
		}

		if (!isStarted) {
			return path.startsWith("startingsoon") ? (
				""
			) : (
				<Redirect to="/startingsoon" />
			);
		} 
 
		if (invitation.status === invitationStatus.FINISHED) {
			return path.startsWith("Success") ? "" : <Redirect to="/Success" />;
		}

		return false;
	};

	checkRoutesReg = () => {
		const { campaignLink = {}, campaignLinkDTO = {} } = this.props;
		const path =
			window.location.href.split("#/").length > 1
				? window.location.href.split("#/")[1]
				: "";

		if (campaignLink.error || campaignLinkDTO.startDate === null) {
			return path.startsWith("badtoken") ? "" : <Redirect to="/badtoken" />;
		}

		if (campaignLink && campaignLink.restTimeToEnd < 0) {
			return path.startsWith("expired") ? "" : <Redirect to="/expired" />;
		}

		return false;
	};

	checkCurrentQuestionRoute = () => {
		const { currentQuestion } = this.props;
		const path =
			window.location.href.split("#/").length > 1
				? window.location.href.split("#/")[1]
				: "";
		if (currentQuestion) {
			return path.startsWith("test") ? "" : <Redirect to={"/test"} />;
		}
	};
	render() {
		const { authenticationState, LoadingLink } = this.props;
		return (
			<Router>
				<I18nextProvider i18n={i18n}>
					<Card
						className={classnames("ah-card", {
							"ah-small-card": smallCardPaths.includes(
								window.location.pathname
							),
						})}
					>
						{"reg" in parse(window.location.search) ? (
							LoadingLink ? (
								<Loader />
							) : (
								<Fragment>
									<Routes checkRoutes={this.checkRoutesReg} />
								</Fragment>
							)
						) : authenticationState.loading ? (
							<Loader />
						) : (
							<Fragment>
								{this.checkCurrentQuestionRoute()}
								<Routes checkRoutes={this.checkRoutes} />
							</Fragment>
						)}
					</Card>
				</I18nextProvider>
			</Router>
		);
	}
}

const mapStateToPros = ({ authentication, questions, campaignLink }) => {
	return {
		authenticationState: authentication.state,
		invitation: authentication.invitation,
		campaign: authentication.campaign,
		counter: questions.counter,
		currentQuestion: questions.currentQuestion,
		restTimeToStart: authentication.restTimeToStart,
		restTimeToEnd: authentication.restTimeToEnd,
		campaignLink: campaignLink.campaign,
		campaignLinkDTO: campaignLink.campaign.campaignLinkDTO,
		LoadingLink: campaignLink.loading,
	};
};

export const Container = connect(mapStateToPros, {
	requestAuthentication,
	setMode,
	getCampaignLink,
})(ContainerCmp);
