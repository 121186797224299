import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios'; 

import instance from "../shared/utils/instance";
import instructions from "./instructions/reducer";
import authentication from "./authentication/reducer";
import invitations from "./campaignInfo/reducer";
import questions from "./questions/reducer";
import candidat from "./candidat/reducer";
import campaignLink from "./campaignLink/reducer";

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createDriver(instance),
});

export const reduxRequestsMiddleware = requestsMiddleware;

export default combineReducers({
	form: formReducer,
	requests:requestsReducer,
	instructions,
	candidat,
	invitations,
	questions,
	authentication,
	campaignLink,
});
