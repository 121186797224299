import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Label } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import { t } from "../../i18n";
import { ButtonG } from "../../components/form/Button";
import { getExperience } from "../../redux/candidat/action";
import {
  required,
  email,
  number,
  minValueInput,
  isValidName
} from "../../components/form/validationRules";
import renderField from "../../components/form/ValidationInput";
import RenderSelectInput from "../../components/form/ValidationInput/RenderSelectInput";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangeExperience = (e) => {
    this.setState({ experiences: e });
  };
  componentDidMount = () => {
    this.props.getExperience();
  };
  render() {
    let { experienceOptions } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit}
        className="pl-5 pr-5 ah-home-validation"
      >
        <Field
          name="firstName"
          component={renderField}
          type="text"
          id="firstName"
          label={t("FirstName")}
          validate={[required, minValueInput, isValidName]}
        />
        <Field
          name="lastName"
          component={renderField}
          type="text"
          id="lastName"
          label={t("LastName")}
          validate={[required, minValueInput, isValidName]}
        />
        <Field
          name="email"
          component={renderField}
          type="email"
          id="email"
          readOnly={true}
          label={t("E-mail")}
          validate={[required, email]}
          disabled
        />
        <Field
          name="phone"
          component={renderField}
          type="text"
          id="phone"
          label={t("Phone")}
          validate={[number, required]}
        />
        <Row className="border-bottom mt-5">
          <Col xs={4} sm={3}>
            <Label className="ah-lbl-input">{t("Experience")}</Label>
          </Col>
          <Col xs={8} sm={9}>
            <Field
              name="refExperienceId"
              id="refExperienceId"
              component={RenderSelectInput}
              options={ experienceOptions.map(e => {
                return { label: e.label, value: e.id };
              })
              }
              placeholder={t("Select")}
              onChange={this.handleChangeExperience}
            />
          </Col>
        </Row>
        <div className="text-right">
          <ButtonG
            className="ah-btn-SuivantHome"
            type="submit"
            color="primary"
            size="lg"
          >
            {t("Next")}
          </ButtonG>
        </div>
      </form>
    );
  }
}
Login.defaultProps = {
  onSubmit: undefined,
};
const mapStateToPros = (state) => {
  return {
    initialValues: state.authentication.candidate,
    experienceOptions: state.candidat.experiencesOption,
  };
};
export default connect(mapStateToPros, { getExperience })(
  reduxForm({
    form: "candidat",
    enableReinitialize: true,
  })(Login)
);
