import { call, put, takeLatest, delay } from "redux-saga/effects";

import {
  REQUEST_AUTHENTICATION,
  RECEIVE_AUTHENTICATION_ERROR,
  GET_RECRUITER_LOGO
} from "./actionsType";
import { getAuthenticationApi, getLogoRecruiterApi } from "./api";
import {
  receiveAuthenticationData,
  setLogoRecruiter,
  getLogoRecruiterError
} from "./action";
import {
  getCurrentRootEntity
} from "../rootEntity/action";

function* setAuthentication({ token }) {
  try {
    const response = yield call(getAuthenticationApi, token);

    yield delay(50); // TODO Must be deleted in the PROD
    if ([200].includes(response.status)) {
      yield put(receiveAuthenticationData(response.data));
      yield put(getCurrentRootEntity());
    
      const bearerToken = response.headers.authorization;
      if (bearerToken && bearerToken.slice(0, 7) === "Bearer ") {
        const jwt = bearerToken.slice(7, bearerToken.length);
        localStorage.setItem("authenticationToken", jwt);
      }
    }
  } catch (error) {
    yield put({ type: RECEIVE_AUTHENTICATION_ERROR, authData: error });
  }
}

function* getLogoRecruiter({ payload }) {
  try {
    let logo = null;
    if (payload != null) logo = yield call(getLogoRecruiterApi, payload);
    if ([200].includes(logo.status)) {
      yield put(setLogoRecruiter(logo.data));
    }
  } catch (error) {
    yield put(getLogoRecruiterError(error));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(REQUEST_AUTHENTICATION, setAuthentication);
  yield takeLatest(GET_RECRUITER_LOGO, getLogoRecruiter);
}
